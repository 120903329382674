import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { GetVisitList, Check_set, PostVisit, PutAccounting } from "~slices/Check";

import { styled } from "@mui/material/styles";
import AccountingPopup from "~/components/block/AccountingPopup";

import { FormatTime, ParseTime, FormatOpeningHours } from "~/components/functions/DateTimeFunctions";
import { GetClientList } from "~slices/Client";

import { now } from "lodash";

const Check = () => {
    const dispatch = useDispatch();
    const CheckState = useSelector((state) => state.Check);
    const [visitDialog, setVisitDialog] = React.useState(false);

    React.useEffect(() => {
        const params = {
            check_flag: 0,
        };
        dispatch(GetVisitList(params)).then((data) => {});
    }, []);

    const HandleOrder = (e) => {
        dispatch(Check_set(["sort", e.target.value]));
        const orderData = e.target.value.split(",");
        const column = orderData[0];
        const order = orderData[1];

        const params = {
            check_flag: 0,
            column: column,
            order: order,
        };
        dispatch(GetVisitList(params)).then((data) => {});
    };
    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Button
                        variant="contained"
                        onClick={() => setVisitDialog(true)}
                        sx={{ height: "5rem", width: "10rem", mb: 1 }}
                        color="error"
                    >
                        新規来店
                    </Button>

                    <FormControl sx={{ m: 1, width: "12rem" }}>
                        <InputLabel id="select-order-label">並び替え</InputLabel>
                        <Select
                            fullWidth
                            labelId="select-order-label"
                            label="並び替え"
                            value={CheckState.sort}
                            onChange={HandleOrder}
                        >
                            <MenuItem value={"visit_time,asc"}>来店時間</MenuItem>
                            <MenuItem value={"client_name,asc"}>名前</MenuItem>
                            <MenuItem value={"number_people,desc"}>人数</MenuItem>
                            <MenuItem value={"seat_number,desc"}>座席番号</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Dialog
                    onClose={() => {
                        const params = {
                            check_flag: 0,
                        };
                        dispatch(GetVisitList(params));
                        dispatch(Check_set(["OpenAccountingPopup", false]));
                    }}
                    open={CheckState.OpenAccountingPopup}
                >
                    <AccountingPopup />
                </Dialog>

                <Dialog onClose={() => setVisitDialog(false)} open={visitDialog}>
                    <VisitPopup onClose={() => setVisitDialog(false)} />
                </Dialog>

                <VisitTable />
            </Container>
        </React.Fragment>
    );
};

const VisitPopup = (props) => {
    const { onClose } = props;
    const dispatch = useDispatch();
    const CheckState = useSelector((state) => state.Check);
    const visit_list = CheckState.visit_list;
    const ClientState = useSelector((state) => state.Client);

    const [client_id, setClientId] = React.useState(0);
    const [client_name, setClientName] = React.useState("");
    const [seat_number, setSeatNumber] = React.useState("");
    const [number_people, setNumberPeople] = React.useState(1);

    React.useEffect(() => {
        dispatch(GetClientList()).then((data) => {});
    }, []);
    const client_list = ClientState.client_list;

    const HandleClientId = (e) => {
        const this_client_id = e.target.value;
        setClientId(this_client_id);
        if (this_client_id == 0) {
            setClientName("");
            return;
        }
        const client_data = client_list.filter((item) => item?.client_id == this_client_id)[0];
        setClientName(client_data.client_name);
    };

    const onVisit = () => {
        if (!client_name) {
            alert("お客様名が入力されていません");
            return;
        }

        const params = {
            check_flag: 0,
            create_data: {
                client_id: client_id,
                client_name: client_name,
                seat_number: seat_number,
                number_people: number_people,
                visit_time: FormatTime(new Date(), "date_time"),
                business_day: FormatOpeningHours(new Date(), "date"),
            },
        };

        // dispatch(Check_set(["visit_list", [...visit_list, params]]));
        dispatch(PostVisit(params)).then((data) => {
            onClose();
        });
    };

    return (
        <>
            <Grid container sx={{ width: "100%", p: 2 }} direction="row">
                <FormControl>
                    <InputLabel id="select-menu-label">お客様名</InputLabel>
                    <Select
                        sx={{ m: 1, width: "12rem" }}
                        fullWidth
                        labelId="select-menu-label"
                        label="お客様名"
                        value={client_id}
                        onChange={HandleClientId}
                    >
                        <MenuItem value={0}>その他</MenuItem>
                        {client_list.map((item, index) => (
                            <MenuItem key={index} value={item.client_id}>
                                {item.client_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* {client_id == 0 && ( */}
                <TextField
                    sx={{ m: 1, visibility: client_id == 0 ? "visible" : "hidden" }}
                    label="お客様名"
                    // type="datetime-local"
                    value={client_name}
                    onChange={(e) => setClientName(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid
                container
                sx={{ width: "100%", p: 2 }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                {/* <TextField
                    label="人数"
                    value={number_people}
                    onChange={(e) => setNumberPeople(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                /> */}

                <FormControl>
                    <InputLabel id="select-number_people-label">人数</InputLabel>
                    <Select
                        sx={{ m: 1, width: "6rem" }}
                        fullWidth
                        labelId="select-number_people-label"
                        label="人数"
                        value={Number(number_people)}
                        onChange={(e) => setNumberPeople(e.target.value)}
                    >
                        {[...Array(10)].map((val, index) => (
                            <MenuItem value={index + 1} key={index}>
                                {index + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    label="席番号"
                    value={seat_number}
                    onChange={(e) => setSeatNumber(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <Button onClick={onVisit} variant="contained" sx={{ height: "3.5rem", width: "6rem" }}>
                    来店
                </Button>
            </Grid>
        </>
    );
};

const VisitTable = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const CheckState = useSelector((state) => state.Check);
    const informationState = useSelector((state) => state.Information);
    const ConfigState = informationState.config;
    const visit_list = CheckState.visit_list;

    const HeadGrid = styled(Grid)({
        padding: 2,
        border: "0.1rem solid",
        background: theme.palette.primary.light,
        // fontSize: "0.8rem",
        textAlign: "center",
        borderRadius: 2,
        fontWeight: "bold",
        fontSize: "1rem",
    });

    const BodyGrid = styled(Grid)({
        padding: 2,
        // border: "1px solid grey",
        borderTop: "1px solid #b6b3b3",
        borderLeft: "1px solid #b6b3b3",
        borderRight: "1px solid #b6b3b3",
        // fontSize: "0.9rem",
        overflowWrap: "break-word",
        borderRadius: 2,
        fontSize: "1rem",
    });
    const BodyGridNOBT = styled(Grid)({
        padding: 2,
        borderLeft: "1px solid #b6b3b3",
        borderRight: "1px solid #b6b3b3",
        borderBottom: "1px solid #b6b3b3",
        // fontSize: "0.9rem",
        overflowWrap: "break-word",
        borderRadius: 2,
        fontSize: "1rem",
    });

    const SelectVisit = (visit_data) => {
        dispatch(Check_set(["visit_data", visit_data]));
        dispatch(Check_set(["OpenAccountingPopup", true]));
    };

    const HandleClickMenu = (accounting) => {
        console.log(accounting);

        let checkFlg = window.confirm(`｢${accounting.menu_name}｣を準備完了にしますか?`);

        if (!checkFlg) return;

        const params = {
            visit_id: accounting.visit_id,
            accounting_id: accounting.accounting_id,
            update_data: {
                status: 1,
            },
        };

        dispatch(PutAccounting(params)).then((data) => {
            dispatch(GetVisitList(params));
        });
    };

    return (
        <>
            <Grid container sx={{ borderBottom: "1px solid grey" }}>
                <HeadGrid item xs={1}>
                    No
                </HeadGrid>
                <HeadGrid item xs={4}>
                    名前
                </HeadGrid>
                <HeadGrid item xs={3}>
                    来店時間
                </HeadGrid>
                <HeadGrid item xs={1}>
                    人数
                </HeadGrid>
                <HeadGrid item xs={1}>
                    席番号
                </HeadGrid>
                <HeadGrid item xs={2}>
                    詳細
                </HeadGrid>

                {visit_list.map((visit_data, index) => (
                    <React.Fragment key={index}>
                        <Grid container sx={{ border: "solid 0.1rem" }}>
                            <BodyGrid
                                item
                                xs={1}
                                sx={{
                                    textAlign: "left",
                                    // pl: 2,
                                    pt: "0.2rem",
                                    textAlign: "center",
                                    fontSize: "1.5rem",
                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                }}
                            >
                                <Box> {index + 1}</Box>
                            </BodyGrid>
                            <BodyGrid
                                item
                                xs={4}
                                sx={{
                                    textAlign: "left",
                                    pl: 2,
                                    pt: 1,
                                    fontSize: "1.5rem",
                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                }}
                                onClick={() => SelectVisit(visit_data)}
                            >
                                {visit_data.client_name}様
                            </BodyGrid>

                            <BodyGrid
                                item
                                xs={3}
                                sx={{
                                    textAlign: "center",
                                    pt: 2,
                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                }}
                                onClick={() => SelectVisit(visit_data)}
                            >
                                {FormatTime(ParseTime(visit_data?.visit_time))}
                            </BodyGrid>

                            <BodyGrid
                                item
                                xs={1}
                                sx={{
                                    textAlign: "center",
                                    pt: 2,
                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                }}
                                onClick={() => SelectVisit(visit_data)}
                            >
                                {visit_data.number_people}人
                            </BodyGrid>
                            <BodyGrid
                                item
                                xs={1}
                                sx={{
                                    textAlign: "center",
                                    pt: 2,
                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                }}
                                onClick={() => SelectVisit(visit_data)}
                            >
                                {visit_data.seat_number}
                            </BodyGrid>

                            <BodyGrid
                                item
                                xs={2}
                                sx={{
                                    textAlign: "right",
                                    pt: 1,
                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                }}
                            >
                                <Button fullWidth onClick={() => SelectVisit(visit_data)}>
                                    選択
                                </Button>
                            </BodyGrid>

                            {ConfigState.preparation ? (
                                <React.Fragment>
                                    {visit_data?.accounting_list?.length ? (
                                        <>
                                            <BodyGridNOBT
                                                item
                                                xs={1}
                                                sx={{
                                                    pt: 1,
                                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                                }}
                                            ></BodyGridNOBT>
                                            <BodyGrid
                                                item
                                                xs={11}
                                                sx={{
                                                    pt: 1,
                                                    backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                                }}
                                            >
                                                {visit_data.accounting_list.map((accounting, i) => (
                                                    <React.Fragment key={i}>
                                                        <Box
                                                            sx={{
                                                                display: "inline-block",
                                                                padding: "0.2rem",
                                                                border: "solid gray 1px",
                                                                borderRadius: "0.5rem",
                                                                margin: "0.2rem",
                                                                background:
                                                                    accounting.status == 2 ? "#acacac" : "#e7e7e7",
                                                            }}
                                                            onClick={() => HandleClickMenu(accounting)}
                                                        >
                                                            {" "}
                                                            {accounting.menu_name}
                                                        </Box>
                                                    </React.Fragment>
                                                ))}
                                            </BodyGrid>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* <BodyGridNOBT
                                    item
                                    xs={4}
                                    sx={{
                                        pt: 1,
                                        backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                    }}
                                ></BodyGridNOBT>
                                <BodyGrid
                                    item
                                    xs={8}
                                    sx={{
                                        pt: 1,
                                        backgroundColor: visit_data?.exit_time ? "#faffaf" : "none",
                                    }}
                                >
                                    あああ
                                </BodyGrid> */}
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </>
    );
};

export default Check;
