import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
    Switch,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { GetMenuList } from "~slices/Menu";
import { Information_set, PutConfig } from "~slices/Information";

const Configuration = () => {
    const dispatch = useDispatch();
    const InformationState = useSelector((state) => state.Information);
    const MenuState = useSelector((state) => state.Menu);
    const config = InformationState.config;
    const permission = InformationState.user_info.permission;

    React.useEffect(() => {
        dispatch(GetMenuList());
    }, []);

    const HandleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatch(Information_set(["config", { ...config, [name]: value }]));
    };
    const handleCheckd = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        dispatch(Information_set(["config", { ...config, [name]: value }]));
    };
    const HandleSave = () => {
        dispatch(PutConfig({ update_data: { type: "general", config: config } }));
    };
    const theme_color_list = [
        { color_name: "赤", color: "red" },
        { color_name: "紫", color: "purple" },
        { color_name: "ピンク", color: "pink" },
        { color_name: "オレンジ", color: "orange" },
        { color_name: "青", color: "blue" },
        { color_name: "緑", color: "green" },
        { color_name: "黒", color: "grey" },
        { color_name: "茶", color: "brown" },
    ];
    return (
        <React.Fragment>
            <Container sx={{ pt: 2, textAlign: "center" }}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="システム名"
                        value={config.system_name}
                        name="system_name"
                        onChange={HandleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControlLabel
                        control={<Switch checked={config.bottle_keep} onChange={handleCheckd} name="bottle_keep" />}
                        label="ボトルキープ料金"
                    />
                    {config.bottle_keep ? (
                        <>
                            <TextField
                                sx={{ m: 1, width: "20rem" }}
                                label="ボトルキープ時間料金価格"
                                value={config.bottle_keep_price}
                                name="bottle_keep_price"
                                type="number"
                                onChange={HandleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </>
                    ) : (
                        ""
                    )}
                    <FormControlLabel
                        control={<Switch checked={config.time_price} onChange={handleCheckd} name="time_price" />}
                        label="時間料金"
                    />
                    {config.time_price ? (
                        <>
                            <TextField
                                sx={{ m: 1, width: "20rem" }}
                                label="最初の1時間"
                                value={config.first_1_hour}
                                name="first_1_hour"
                                type="number"
                                onChange={HandleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                sx={{ m: 1, width: "20rem" }}
                                label="最初2時間"
                                value={config.first_2_hour}
                                name="first_2_hour"
                                type="number"
                                onChange={HandleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                sx={{ m: 1, width: "20rem" }}
                                label="チャージ(最初の固定時間料金との差額)"
                                value={config.charge}
                                name="charge"
                                type="number"
                                onChange={HandleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                sx={{ m: 1, width: "20rem" }}
                                label="以降30分ごとの価格"
                                value={config.half_hour_price}
                                name="half_hour_price"
                                type="number"
                                onChange={HandleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </>
                    ) : (
                        ""
                    )}
                    <FormControlLabel
                        control={<Switch checked={config.staff_drink} onChange={handleCheckd} name="staff_drink" />}
                        label="スタッフドリンク"
                    />
                    {config.staff_drink ? (
                        <>
                            <FormControl>
                                <InputLabel id="attendance-staff_drink_back-label">
                                    スタッフドリンクバック金額
                                </InputLabel>
                                <Select
                                    sx={{ m: 1, width: "8rem" }}
                                    fullWidth
                                    labelId="attendance-staff_drink_back-label"
                                    name="staff_drink_back"
                                    value={config.staff_drink_back}
                                    onChange={HandleChange}
                                    defaultValue={1}
                                >
                                    {[0, 5, 10, 15, 20, 25, 30].map((val, index) => (
                                        <MenuItem key={index} value={val}>
                                            {val}%
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    ) : (
                        ""
                    )}
                    <FormControlLabel
                        control={<Switch checked={config.menu_view} onChange={handleCheckd} name="menu_view" />}
                        label="メニュー画面の表示"
                    />
                    {permission == "god" ? (
                        <FormControlLabel
                            control={<Switch checked={config.preparation} onChange={handleCheckd} name="preparation" />}
                            label="準備ステータス"
                        />
                    ) : (
                        ""
                    )}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={config.receipt_confirmation}
                                onChange={handleCheckd}
                                name="receipt_confirmation"
                            />
                        }
                        label="会計時の領収書表示確認"
                    />
                    <TextField
                        sx={{ m: 1, width: "10rem" }}
                        label="カード手数料"
                        value={config.cache_res_commission}
                        name="cache_res_commission"
                        onChange={HandleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                    ※カード・キャッシュレスの手数料
                    <Grid item sx={{ mt: "1rem" }}>
                        <FormControl>
                            <InputLabel id="attendance-deadline-label">勤怠確認締め日</InputLabel>
                            <Select
                                sx={{ m: 1, width: "8rem" }}
                                fullWidth
                                labelId="attendance-deadline-label"
                                name="attendance_deadline"
                                value={config.attendance_deadline}
                                onChange={HandleChange}
                                defaultValue={1}
                            >
                                <MenuItem value={1}>月末</MenuItem>
                                <MenuItem value={16}>15日</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Typography> 領収書</Typography>
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="但し書き"
                        value={config.receipt_proviso}
                        name="receipt_proviso"
                        onChange={HandleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder={"〇〇代として"}
                    />
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="住所"
                        value={config.receipt_address}
                        name="receipt_address"
                        onChange={HandleChange}
                        multiline
                        type="text"
                        rows={3}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="電話番号"
                        value={config.receipt_telephone}
                        name="receipt_telephone"
                        onChange={HandleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="インボイス登録番号"
                        value={config.receipt_invoice}
                        name="receipt_invoice"
                        onChange={HandleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Typography> 表示設定</Typography>
                    <Grid item sx={{ mt: "1rem" }}>
                        <FormControl>
                            <InputLabel id="select-number_people-label">テーマカラー</InputLabel>
                            <Select
                                sx={{ m: 1, width: "8rem" }}
                                fullWidth
                                labelId="select-number_people-label"
                                name="theme_color"
                                value={config.theme_color}
                                onChange={HandleChange}
                            >
                                {theme_color_list.map((theme_color, index) => (
                                    <MenuItem value={theme_color.color} key={index}>
                                        {theme_color.color_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="お会計画面の枠色"
                        value={config.accounting_detail_background}
                        name="accounting_detail_background"
                        onChange={HandleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="お会計画面のロゴ"
                        value={config.accounting_detail_logo}
                        name="accounting_detail_logo"
                        onChange={HandleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl>
                        <InputLabel id="select-type-label">商品ジャンルのデフォルト</InputLabel>
                        <Select
                            sx={{ m: 1, width: "12rem" }}
                            fullWidth
                            labelId="select-type-label"
                            label="商品ジャンルのデフォルト"
                            name="default_type"
                            value={config.default_type}
                            onChange={HandleChange}
                        >
                            <MenuItem value={0}>その他</MenuItem>
                            {config.staff_drink && <MenuItem value={9}>スタッフドリンク</MenuItem>}
                            {MenuState.menu_type_list.map((val, index) => (
                                <MenuItem key={index} value={val.type}>
                                    {val.menu_type_name}
                                </MenuItem>
                            ))}
                            {/* <MenuItem value={1}>一般ドリンク</MenuItem> */}
                            {/* <MenuItem value={2}>シャンパン</MenuItem>
                    <MenuItem value={3}>ボトル</MenuItem>
                    <MenuItem value={9}>スタッフドリンク</MenuItem> */}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        sx={{ height: "5rem", width: "10rem", marginRight: "0", marginLeft: "auto" }}
                        onClick={HandleSave}
                    >
                        保存
                    </Button>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Configuration;
