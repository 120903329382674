import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { GetStaffList, Staff_set, PostStaff, DeleteStaff } from "~slices/Staff";

import { styled } from "@mui/material/styles";

import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

import { now } from "lodash";

const HeadGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // background: "#9bc6fa",
    // fontSize: "0.8rem",
    textAlign: "center",
    borderRadius: 2,
    fontWeight: "bold",
    fontSize: "1rem",
});
const BodyGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // fontSize: "0.9rem",
    overflowWrap: "break-word",
    borderRadius: 2,
    fontSize: "1rem",
});

const StaffSetting = () => {
    const dispatch = useDispatch();

    const [birthday, setBirthday] = React.useState("");
    const [staff_name, setStaffName] = React.useState("");
    const [hourly, setHourly] = React.useState(1000);
    React.useEffect(() => {
        dispatch(GetStaffList()).then((data) => {
            console.log(data);
        });
    }, []);
    const CreateStaff = () => {
        const params = {
            create_data: {
                birthday: birthday,
                staff_name: staff_name,
                hourly: hourly,
            },
        };
        dispatch(PostStaff(params)).then((data) => {
            setStaffName("");
        });
    };

    // const HandleChangeType = (e) => {
    //     setType(e.target.value);

    //     const params = {
    //         type: e.target.value,
    //     };
    //     dispatch(GetStaffList(params)).then((data) => {});
    // };
    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <Typography variant="h3" align="center" sx={{ pb: 2 }}>
                    スタッフ管理
                </Typography>
                {/* <Grid>
                    <FormControl>
                        <InputLabel id="select-type-label">種別</InputLabel>
                        <Select
                            sx={{ m: 1, width: "12rem" }}
                            fullWidth
                            labelId="select-type-label"
                            label="種別"
                            value={type}
                            onChange={HandleChangeType}
                        >
                            <StaffItem value={2}>シャンパン</StaffItem>
                            <StaffItem value={3}>ボトル</StaffItem>
                        </Select>
                    </FormControl>
                </Grid> */}
                追加
                <Grid sx={{ border: "1px solid grey", p: 2 }} container direction="row" justifyContent="space-around">
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="名前"
                        value={staff_name}
                        onChange={(e) => setStaffName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{ m: 1, width: "10rem" }}
                        label="誕生日"
                        type="date"
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        sx={{ m: 1, width: "10rem" }}
                        label="時給"
                        value={hourly}
                        onChange={(e) => setHourly(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button variant="contained" sx={{ height: "4rem", width: "6rem" }} onClick={CreateStaff}>
                        登録
                    </Button>
                </Grid>
                <StaffTable />
            </Container>
        </React.Fragment>
    );
};

const StaffTable = (props) => {
    const StaffState = useSelector((state) => state.Staff);
    const dispatch = useDispatch();
    const staff_list = StaffState.staff_list;
    const DellStaff = (staff_data) => {
        dispatch(
            DeleteStaff({
                staff_id: staff_data.staff_id,
            })
        );
    };
    // const staff_list = [{ staff_name: "aaaa", manu_price: 1000 }];

    return (
        <>
            <Grid container sx={{ mt: 1 }}>
                <HeadGrid item xs={6}>
                    名前
                </HeadGrid>
                <HeadGrid item xs={2}>
                    誕生日
                </HeadGrid>
                <HeadGrid item xs={2}>
                    時給
                </HeadGrid>
                <HeadGrid item xs={2}></HeadGrid>

                {staff_list.map((staff, index) => (
                    <React.Fragment key={index}>
                        <BodyGrid item xs={6} sx={{ p: "4px" }}>
                            {staff.staff_name}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {staff.birthday}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {staff.hourly}
                        </BodyGrid>

                        <BodyGrid item xs={2} sx={{ textAlign: "right" }}>
                            <Button fullWidth color="error" onClick={() => DellStaff(staff)}>
                                削除
                            </Button>
                        </BodyGrid>
                    </React.Fragment>
                ))}
                {/* 合計{price}円
                <Grid>
                    税込み
                    <div style={{ fontSize: "2rem", display: "inline" }}>{Math.floor(price * 1.1)}</div>円
                </Grid> */}
            </Grid>
        </>
    );
};

export default StaffSetting;
