import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Container,
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    styled,
} from "@mui/material";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";
import { CheckHistory_set, SerchVisit, DeleteVisit } from "~slices/CheckHistory";
import AccountingPopup from "~/components/block/AccountingPopup";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

import {
    AccountingHistory_set,
    SerchAccounting,
    DeleteAccounting,
    DownloadAccounting,
} from "~slices/AccountingHistory";

const AccountingHistory = () => {
    const CheckHistoryState = useSelector((state) => state.CheckHistory);
    React.useEffect(() => {
        const params = {
            // type: type,
        };
        // dispatch(GetMenuList(params)).then((data) => {
        //     console.log(data);
        // });
    }, []);

    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <SearchCriteria />
                <VisitTable />
            </Container>
        </React.Fragment>
    );
};

const SearchCriteria = () => {
    const dispatch = useDispatch();
    const AccountingHistoryState = useSelector((state) => state.AccountingHistory);
    const exit_time_start = AccountingHistoryState.exit_time_start;
    const exit_time_end = AccountingHistoryState.exit_time_end;
    const client_name = AccountingHistoryState.client_name;
    const menu_name = AccountingHistoryState.menu_name;

    React.useEffect(() => {
        HandleSerch();
    }, []);

    const HandleSerch = () => {
        const params = {
            exit_time_start: exit_time_start,
            exit_time_end: exit_time_end,
            check_flag: 1,
            client_name: client_name,
            menu_name: menu_name,
        };
        dispatch(SerchAccounting(params));
    };

    const SubMonth = () => {
        // const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");
        dispatch(
            AccountingHistory_set([
                "exit_time_start",
                FormatTime(ParseTime(exit_time_start).setMonth(ParseTime(exit_time_start).getMonth() - 1), "date_time"),
            ])
        );
        dispatch(
            AccountingHistory_set([
                "exit_time_end",
                FormatTime(ParseTime(exit_time_end).setMonth(ParseTime(exit_time_end).getMonth() - 1), "date_time"),
            ])
        );
    };
    const AddMonth = () => {
        // const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");
        dispatch(
            AccountingHistory_set([
                "exit_time_start",
                FormatTime(ParseTime(exit_time_start).setMonth(ParseTime(exit_time_start).getMonth() + 1), "date_time"),
            ])
        );
        dispatch(
            AccountingHistory_set([
                "exit_time_end",
                FormatTime(ParseTime(exit_time_end).setMonth(ParseTime(exit_time_end).getMonth() + 1), "date_time"),
            ])
        );
    };

    return (
        <>
            <Box sx={{ p: 2, mb: 2, border: "1px dashed grey" }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <TextField
                        sx={{ m: 1 }}
                        label="会計時間"
                        type="datetime-local"
                        value={exit_time_start}
                        onChange={(e) => dispatch(AccountingHistory_set(["exit_time_start", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    ～
                    <TextField
                        sx={{ m: 1 }}
                        label="会計時間"
                        type="datetime-local"
                        value={exit_time_end}
                        onChange={(e) => dispatch(AccountingHistory_set(["exit_time_end", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Grid container direction="column" sx={{ width: "6rem" }}>
                        <Button onClick={SubMonth} variant="contained" sx={{ height: "1.5rem", width: "6rem" }}>
                            △先月
                        </Button>
                        <Button onClick={AddMonth} variant="contained" sx={{ height: "1.5rem", width: "6rem" }}>
                            ▽次月
                        </Button>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <TextField
                        sx={{ m: 1 }}
                        label="お客様名"
                        value={client_name}
                        onChange={(e) => dispatch(AccountingHistory_set(["client_name", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        sx={{ m: 1 }}
                        label="商品名"
                        value={menu_name}
                        onChange={(e) => dispatch(AccountingHistory_set(["menu_name", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button onClick={HandleSerch} variant="contained" sx={{ height: "4rem", width: "6rem" }}>
                        検索
                    </Button>
                </Grid>
            </Box>
        </>
    );
};

const VisitTable = () => {
    const dispatch = useDispatch();
    const AccountingHistoryState = useSelector((state) => state.AccountingHistory);
    const CheckHistoryState = useSelector((state) => state.CheckHistory);
    const total_amount = AccountingHistoryState.total_amount;

    const OnDeleteVisit = (row) => {
        const res = confirm(`削除しますか?`);
        if (!res) return;
        dispatch(DeleteVisit({ visit_id: row.visit_id }));
    };

    const columns = [
        { field: "accounting_id", headerName: "来店ID", minWidth: 100, flex: 1, hide: true },
        { field: "client_name", headerName: "名前", minWidth: 200, flex: 1, hide: false },
        {
            field: "visit_time",
            headerName: "来店時間",
            minWidth: 170,
            flex: 1,
            hide: false,
            valueGetter: (params) => params.row?.visit_time,
        },
        { field: "menu_name", headerName: "商品名", minWidth: 200, flex: 1, hide: false },
        { field: "memo", headerName: "メモ", minWidth: 200, flex: 1, hide: true },
        { field: "change", headerName: "お釣り", minWidth: 200, flex: 1, hide: true },
        {
            field: "menu_price",
            headerName: "料金",
            minWidth: 100,
            flex: 1,
            hide: false,
            valueGetter: (params) => Number(params.row?.menu_price).toLocaleString(),
        },
        // {
        //     field: "",
        //     headerName: "",
        //     sortable: false,

        //     // flex: 1,
        //     renderCell: (params) => (
        //         <Button variant="contained" color="error" onClick={() => OnDeleteVisit(params.row)}>
        //             削除
        //         </Button>
        //     ),
        // },
    ];

    const HandleDownload = () => {
        const params = {
            exit_time_start: AccountingHistoryState.exit_time_start,
            exit_time_end: AccountingHistoryState.exit_time_end,
            check_flag: 1,
            client_name: AccountingHistoryState.client_name,
            menu_name: AccountingHistoryState.menu_name,
        };
        dispatch(DownloadAccounting(params));
    };

    return (
        <>
            <div style={{ height: 800, width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row.accounting_id}
                    rows={AccountingHistoryState.accounting_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    disableSelectionOnClick
                    pagination
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
            <Button onClick={HandleDownload}>ダウンロード</Button>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計金額 {Number(total_amount).toLocaleString()}円
            </Grid>
        </>
    );
};
export default AccountingHistory;
