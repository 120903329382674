import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    isOpened: false,
    isConfirmed: false,
    isDeclined: false,
};

export const confirmationModalThunkActions = {
    open: createAsyncThunk("Popup", async (arg, thunkAPI) => {
      

        const { extra, dispatch } = thunkAPI;
        console.log(thunkAPI);

        dispatch(confirmationModalActions.open());

        return new Promise((resolve) => {
            const store = extra.store;

            const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                if (state.Popup.isConfirmed) {
                    console.log("isConfirmed");
                    unsubscribe();
                    resolve(true);
                }
                if (state.Popup.isDeclined) {
                    console.log("isDeclined");
                    unsubscribe();
                    resolve(false);
                }
            });
        });
    }),
};

// Sliceを生成する
const slice = createSlice({
    name: "Popup",
    initialState,
    reducers: {
        Popup_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
        open: (state) => {
            state.isOpened = true;
            state.isDeclined = false;
            state.isConfirmed = false;
        },
        confirm: (state) => {
            state.isOpened = false;
            state.isConfirmed = true;
        },

        decline: (state) => {
            state.isOpened = false;
            state.isDeclined = true;
        },
    },
    extraReducers: (builder) => {
        builder;
        // .addMatcher(
        //     (action) => action.type.endsWith("/pending"),
        //     (state, action) => {
        //         state.Progress = true;
        //     }
        // )
        // .addMatcher(
        //     (action) => action.type.endsWith("/fulfilled"),
        //     (state, action) => {
        //         //
        //     }
        // );
        // .addMatcher(
        //     (action) => action.type.endsWith("/rejected"),
        //     (state, action) => {
        //         state.Progress = false;
        //     }
        // );
    },
});

// Reducerをエクスポートする
export default slice.reducer;
export const confirmationModalActions = slice.actions;

// Action Creatorsをエクスポートする
// export const { Popup_set, decline, confirm, open } = slice.actions;
