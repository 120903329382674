import React from "react";
import { useEffect } from "react";
import Modal from "@mui/material/Modal";

import { Grid } from "@mui/material/";
import { errorHandler_set } from "~slices/ErrorHandler";
import Button from "@mui/material/Button";

import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ErrerPopup = (props) => {
    const dispatch = useDispatch();

    const errorHandlerState = useSelector((state) => state.ErrorHandler);

    const handleClose = () => {
        dispatch(errorHandler_set(["error_flag", false]));
    };
    return (
        <>
            <Dialog open={errorHandlerState.error_flag} onClose={handleClose}>
                <DialogTitle style={{ background: "#fff" }}>{errorHandlerState.message_title}</DialogTitle>
                <DialogContent style={{ background: "#fff" }}>
                    <DialogContentText>{errorHandlerState.error_message}</DialogContentText>
                </DialogContent>
                <DialogActions style={{ background: "#fff" }}>
                    <Button onClick={handleClose} color="primary">
                        確認
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ErrerPopup;
