import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { GetPurchaseList, Purchase_set, PostPurchase, DeletePurchase } from "~slices/Purchase";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

import { now } from "lodash";
import { useHistory } from "react-router";

const Purchase = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const PurchaseCategoryState = useSelector((state) => state.PurchaseCategory);
    const PurchaseState = useSelector((state) => state.Purchase);

    const [unit_price, setUnitPrice] = React.useState(1000);
    const [count, setCount] = React.useState(1);
    const [payment_methods, setPaymentMethods] = React.useState(1);
    const [item_name, setItemName] = React.useState("");
    const [memo, setMemo] = React.useState("");
    const [purchase_date, setPurchaseDate] = React.useState(FormatTime(new Date(), "date"));

    // const [year, setYear] = useState(new Date().getFullYear());
    // const [month, setMonth] = useState(("00" + (new Date().getMonth() + 1)).slice(-2));

    const month = PurchaseState.filter.month;
    const year = PurchaseState.filter.year;
    const [category_name, setCategoryName] = useState("");

    const [inputFlag, setInputFlag] = useState(false);

    React.useEffect(() => {
        const params = { year: year, month: month };
        dispatch(GetPurchaseList(params)).then((data) => {
            console.log(data);
        });
    }, []);

    const HandlePaymentMethods = (e) => {
        setPaymentMethods(e.target.value);
    };
    const HandleChangeMonth = (e) => {
        if (!e.target.value) return;
        const arr = e.target.value.split("-");
        const params = { ...PurchaseState.filter, year: arr[0], month: arr[1] };
        dispatch(Purchase_set(["filter", params]));

        dispatch(GetPurchaseList(params));
    };

    const CreatePurchase = () => {
        if (!item_name && !category_name) {
            alert("品名を入力してください");
            return;
        }

        const params = {
            year: year,
            month: month,
            create_data: {
                payment_methods: payment_methods,
                unit_price: unit_price,
                count: count,
                price: Number(unit_price) * Number(count),
                item_name: item_name,
                memo: memo,
                purchase_date: purchase_date,
                category_name: category_name,
            },
        };
        dispatch(PostPurchase(params)).then((data) => {
            console.log("purchase");
        });
        setItemName("");
    };

    // const HandleChangeType = (e) => {
    //     setType(e.target.value);

    //     const params = {
    //         type: e.target.value,
    //     };
    //     dispatch(GetPurchaseList(params)).then((data) => {});
    // };
    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <Typography variant="h3" align="center" sx={{ pb: 2 }}>
                    仕入れ管理
                </Typography>
                絞り込み
                <Grid sx={{ border: "1px solid grey", p: 2 }} container direction="row">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <TextField
                            sx={{ m: 1 }}
                            label="月"
                            type="month"
                            onChange={HandleChangeMonth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={`${year}-${month}`}
                            // fullWidth
                        />

                        {/* <Button onClick={() => history.push("/Supplier")}>仕入れ先管理</Button> */}
                    </Grid>

                    <FormControl sx={{ m: 1, width: "12rem" }}>
                        <InputLabel id="select-type-category">種別</InputLabel>
                        <Select
                            fullWidth
                            labelId="select-type-category"
                            label="種別"
                            value={PurchaseState.filter.category_name ? PurchaseState.filter.category_name : ""}
                            onChange={(e) => {
                                const params = { ...PurchaseState.filter, category_name: e.target.value };
                                dispatch(Purchase_set(["filter", params]));
                                dispatch(GetPurchaseList(params));
                            }}
                        >
                            <MenuItem value={""}>未選択</MenuItem>
                            {PurchaseCategoryState?.purchase_category_list.map((val, index) => (
                                <MenuItem key={index} value={val?.category_name}>
                                    {val?.category_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ mt: 1, width: "12rem" }} fullWidth>
                        <InputLabel id="select-payment_methods-label_1">支払方法</InputLabel>
                        <Select
                            labelId="select-payment_methods-label_1"
                            label="支払方法"
                            value={PurchaseState.filter.payment_methods ? PurchaseState.filter.payment_methods : ""}
                            onChange={(e) => {
                                const params = { ...PurchaseState.filter, payment_methods: e.target.value };
                                dispatch(Purchase_set(["filter", params]));
                                dispatch(GetPurchaseList(params));
                            }}
                        >
                            <MenuItem value={""}>未選択</MenuItem>
                            <MenuItem value={1}>現金</MenuItem>
                            <MenuItem value={2}>現金以外</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {inputFlag ? (
                    <Grid sx={{ border: "1px solid grey", p: 2, mt: "1rem", mb: "1rem" }} container direction="row">
                        <Grid container direction="row">
                            <FormControl sx={{ m: 1, width: "12rem" }}>
                                <InputLabel id="select-type-category" shrink>
                                    種別
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="select-type-category"
                                    label="種別"
                                    value={category_name}
                                    onChange={(e) => {
                                        setCategoryName(e.target.value);
                                    }}
                                >
                                    <MenuItem value={""}>未選択</MenuItem>
                                    {PurchaseCategoryState?.purchase_category_list.map((val, index) => (
                                        <MenuItem key={index} value={val?.category_name}>
                                            {val?.category_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                sx={{ m: 1, width: "20rem" }}
                                label="品名"
                                value={item_name}
                                onChange={(e) => setItemName(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                sx={{ m: 1, width: "10rem" }}
                                label="仕入日"
                                type="date"
                                value={purchase_date}
                                onChange={(e) => setPurchaseDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <TextField
                            sx={{ m: 1, width: "8rem" }}
                            label="単価"
                            type="number"
                            value={unit_price}
                            onChange={(e) => setUnitPrice(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                        />

                        <TextField
                            sx={{ m: 1, width: "4rem" }}
                            label="数量"
                            type="number"
                            value={count}
                            onChange={(e) => setCount(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <FormControl sx={{ mt: 1, width: "12rem" }} fullWidth>
                            <InputLabel id="select-payment_methods-label">支払方法</InputLabel>
                            <Select
                                labelId="select-payment_methods-label"
                                label="支払方法"
                                value={payment_methods}
                                onChange={HandlePaymentMethods}
                            >
                                <MenuItem value={1}>現金</MenuItem>
                                <MenuItem value={2}>現金以外</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            sx={{ m: 1 }}
                            label="メモ"
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            multiline
                            rows={3}
                            fullWidth
                        />
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                            <Button
                                onClick={() => {
                                    setInputFlag(false);
                                }}
                            >
                                閉じる
                            </Button>
                            <Button variant="contained" sx={{ height: "4rem", width: "6rem" }} onClick={CreatePurchase}>
                                登録
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Button onClick={() => history.push("/PurchaseCategory")}>仕入れ種別管理</Button>
                        <Button
                            onClick={() => {
                                setInputFlag(true);
                            }}
                            variant="contained"
                            sx={{ p: "1rem 2rem", mt: "1rem", mb: "1rem" }}
                        >
                            追加
                        </Button>
                    </Grid>
                )}
                <PurchaseTable month={month} year={year} />
            </Container>
        </React.Fragment>
    );
};

const PurchaseTable = (props) => {
    const { month, year } = props;
    const PurchaseState = useSelector((state) => state.Purchase);
    const dispatch = useDispatch();
    const purchase_list = PurchaseState.purchase_list;
    const DellPurchase = (purchase_data) => {
        dispatch(
            DeletePurchase({
                month: month,
                year: year,
                purchase_id: purchase_data.purchase_id,
            })
        );
    };
    const columns = [
        { field: "purchase_id", headerName: "仕入れID", minWidth: 100, hide: true },
        { field: "purchase_date", headerName: "仕入れ日", minWidth: 110, hide: false },
        { field: "category_name", headerName: "種別", minWidth: 100, hide: false },
        { field: "category_type", headerName: "分類", minWidth: 100, hide: false },
        { field: "item_name", headerName: "品名", minWidth: 120, flex: 1, hide: false },
        { field: "unit_price", headerName: "単価", minWidth: 200, hide: true },
        { field: "count", headerName: "数量", minWidth: 100, hide: true },

        {
            field: "payment_methods",
            headerName: "支払方法",
            minWidth: 100,
            hide: false,
            valueGetter: (params) =>
                Number(params.row?.payment_methods).toLocaleString() == "1"
                    ? "現金"
                    : Number(params.row?.payment_methods).toLocaleString() == "2"
                    ? "現金以外"
                    : "",
        },

        {
            field: "price",
            headerName: "金額",
            minWidth: 100,
            hide: false,
            valueGetter: (params) => Number(params.row?.price).toLocaleString() + "円",
        },
        { field: "memo", headerName: "メモ", minWidth: 200, hide: true },
        {
            field: "詳細",
            headerName: "",
            sortable: false,
            width: 100,
            align: "right",
            // flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    // color="primary"
                    color="error"
                    onClick={() => {
                        DellPurchase(params.row);
                        // dispatch(Attendance_set(["attendance_data", params.row]));
                        // dispatch(Attendance_set(["AttendanceDetailDialog", true]));
                    }}
                >
                    削除
                </Button>
            ),
        },
    ];
    return (
        <>
            <div style={{ height: 800, width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row.purchase_id}
                    rows={purchase_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    disableSelectionOnClick
                    pagination
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
            {/* {purchase_list.map((purchase, index) => (
                    <React.Fragment key={index}>
                        <BodyGrid item xs={8} sx={{ p: "4px" }}>
                            {purchase.item_name}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {purchase.price}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right" }}>
                            <Button fullWidth color="error" onClick={() => DellPurchase(purchase)}>
                                削除
                            </Button>
                        </BodyGrid>
                    </React.Fragment>
                ))} */}
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ height: "3rem", fontSize: "1.2rem" }}
            >
                <Grid>合 計{PurchaseState.total_purchase}円</Grid>
            </Grid>
        </>
    );
};

export default Purchase;
