import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    menu_type_list: [],
};
export const GetMenuTypeList = (arg) => {
    return AsyncFunction({
        type_name: "MenuType/メニュー種別一覧取得",
        method: "get",
        url: "/api/GetMenuTypeList",
        params: arg,
    });
};

export const PostMenuType = (arg) => {
    return AsyncFunction({
        type_name: "MenuType/メニュー種別追加",
        method: "post",
        url: "/api/PostMenuType",
        params: arg,
    });
};

export const DeleteMenuType = (arg) => {
    return AsyncFunction({
        type_name: "MenuType/メニュー種別削除",
        method: "delete",
        url: "/api/DeleteMenuType",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "MenuType",
    initialState,
    reducers: {
        MenuType_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("MenuType") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.menu_type_list) {
                    state.menu_type_list = payload?.menu_type_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { MenuType_set } = slice.actions;
