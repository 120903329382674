import { useDispatch, useSelector } from "react-redux";
import { confirmationModalThunkActions, confirmationModalActions } from "~slices/Popup";

function useConfirmationModalManagement() {
    const PopupState = useSelector((state) => state.Popup);
    const dispatch = useDispatch();
    const isOpened = PopupState.popup_open;

    const open = async () => {
        const { payload } = await dispatch(confirmationModalThunkActions.open());
        return payload;
    };

    const confirm = () => {
        return dispatch(confirmationModalActions.confirm());
    };

    const decline = () => {
        return dispatch(confirmationModalActions.decline());
    };

    return {
        isOpened,
        open,
        confirm,
        decline,
    };
}

export default useConfirmationModalManagement;
