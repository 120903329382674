import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Avatar from "@mui/material/Avatar";
import ButtonBase from "@mui/material/ButtonBase";
import Stack from "@mui/material/Stack";
import InputField from "~/components/inputs/InputField";

import ReactImageBase64 from "react-image-base64";

import { UserInfoPost } from "~slices/Information";
import { Unstable_Grid } from "@mui/system";

const UserInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const informationState = useSelector((state) => state.Information);
    const user_info = informationState.user_info;
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [images, setImages] = useState({ fileData: user_info?.image_path });
    const [errors, setErrors] = useState([]);

    const [published, setpublished] = useState(false);

    React.useEffect(() => {
        const user_id = query.get("user_id");
        console.log(user_id);
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const params = {
            user_id: user_info?.user_id,
            user_name: elem?.user_name?.value,
            self_introduction: elem?.self_introduction?.value,
            image: images?.fileData,
        };

        dispatch(UserInfoPost(params)).then((data) => {
            history.push(`/User?user_id=${user_info?.user_id}`);
        });
    };

    // function base64Encode(...parts) {
    //     return new Promise((resolve) => {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const offset = reader.result.indexOf(",") + 1;
    //             resolve(reader.result.slice(offset));
    //         };
    //         reader.readAsDataURL(new Blob(parts));
    //     });
    // }

    const HandleImageChange = (data) => {
        if (data.result) {
            // let list = images.data;
            // list.push(data);
            // setImages({ data: list });
            setImages(data);
        } else {
            setErrors([...errors, data.messages]);
        }
    };

    // const HandleChange = async (e) => {
    //     console.log(e.target.files[0]);

    //     // const params = new FormData();
    //     // params.append("image_data", e.target.files[0]);

    //     const image_text = await base64Encode(e.target.files[0]);

    //     const params = {
    //         image_data: image_text,
    //     };
    //     dispatch(UserInfoPost(params));
    //     console.log(image_text);
    // };

    return (
        <React.Fragment>
            <Container>
                <form onSubmit={HandleSubmit}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                        {/* <Typography variant="h4" gutterBottom>
                            ユーザー情報
                        </Typography> */}

                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{ mb: 2 }}
                        >
                            <label htmlFor="image_upload">
                                <Avatar
                                    alt={user_info?.user_name}
                                    src={images?.fileData ? images?.fileData : user_info?.image_path}
                                    sx={{ width: 150, height: 150 }}
                                ></Avatar>
                            </label>
                            {/* <Input type="file" id="image_upload" name="image" onChange={HandleChange} hidden /> */}
                            {/* <img src={images?.fileData} /> */}
                            <div style={{ display: "none" }}>
                                <ReactImageBase64
                                    maxFileSize={10485760}
                                    thumbnail_size={500}
                                    drop={false}
                                    hidden={true}
                                    // capture="environment"
                                    multiple={true}
                                    id="image_upload"
                                    name="test"
                                    handleChange={HandleImageChange}
                                />
                            </div>
                            画像を押して変更
                        </Stack>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ border: "solid 1px", borderRadius: 2, p: 2, maxWidth: "350px", width: "90%" }}
                        >
                            {user_info?.email}
                            {/* <InputField
                                type="email"
                                name="email"
                                label="メールアドレス"
                                value={user_info?.email}
                                required
                                disabled
                            /> */}
                            <InputField name="user_name" label="名前" value={user_info?.user_name} required />

                            <InputField
                                name="self_introduction"
                                label="自己紹介"
                                value={user_info?.self_introduction}
                                multiline
                                minRows={4}
                            />

                            <Button variant="contained" type="submit">
                                変更
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                <Stack direction="column" justifyContent="center">
                    <Link to="/ChangeEmail">メールアドレスの変更</Link>
                    <Link to="/ChangePassword">パスワードの変更</Link>
                </Stack>
            </Container>
        </React.Fragment>
    );
};

export default UserInfo;
