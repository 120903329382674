import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

let date = new Date();
date.setDate(1);
date.setHours(12);
date.setMinutes(0);
const month_beginning = FormatTime(date, "date_time");
const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");

// Stateの初期状態
const initialState = {
    accounting_list: [],
    exit_time_start: month_beginning,
    exit_time_end: next_month_beginning,
    client_name: "",
    menu_name: "",
    total_amount: 0,
};
export const SerchAccounting = (arg) => {
    return AsyncFunction({
        type_name: "AccountingHistory/注文履歴取得",
        method: "get",
        url: "/api/SerchAccounting",
        params: arg,
    });
};

export const DeleteAccounting = (arg) => {
    return AsyncFunction({
        type_name: "AccountingHistory/注文履歴削除",
        method: "delete",
        url: "/api/DeleteAccounting",
        params: arg,
    });
};

export const DownloadAccounting = (arg) => {
    return AsyncFunction({
        type_name: "DownloadAccountingHistory/注文履歴ダウンロード",
        method: "get",
        url: "/api/DownloadAccounting",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "AccountingHistory",
    initialState,
    reducers: {
        AccountingHistory_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("AccountingHistory") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.accounting_list) {
                        state.accounting_list = payload?.accounting_list;
                    }
                    if (payload?.total_amount) {
                        state.total_amount = payload?.total_amount;
                    }
                }
            )

            .addMatcher(
                (action) => action.type.match("DownloadAccountingHistory") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    //CSVデータ
                    const data = action.payload;

                    //ダウンロードするCSVファイル名を指定する
                    const filename = "download.csv";

                    //BOMを付与する（Excelでの文字化け対策）
                    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
                    //Blobでデータを作成する
                    const blob = new Blob([bom, data], { type: "text/csv" });

                    //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
                    if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                        //その他ブラウザ
                    } else {
                        //BlobからオブジェクトURLを作成する
                        const url = (window.URL || window.webkitURL).createObjectURL(blob);
                        //ダウンロード用にリンクを作成する
                        const download = document.createElement("a");
                        //リンク先に上記で生成したURLを指定する
                        download.href = url;
                        //download属性にファイル名を指定する
                        download.download = filename;
                        //作成したリンクをクリックしてダウンロードを実行する
                        download.click();
                        //createObjectURLで作成したオブジェクトURLを開放する
                        (window.URL || window.webkitURL).revokeObjectURL(url);
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { AccountingHistory_set } = slice.actions;
