import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { format, parse, differenceInMinutes, subDays } from "date-fns";
import {
    Container,
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    styled,
} from "@mui/material";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";
import { CheckHistory_set, SerchVisit, DeleteVisit, PutVisit, DownloadVisit } from "~slices/CheckHistory";
import AccountingPopup from "~/components/block/AccountingPopup";
import { FormatTime, ParseTime, FormatOpeningHours } from "~/components/functions/DateTimeFunctions";
import {
    PurchaseCategory_set,
    GetPurchaseCategoryList,
    PostPurchaseCategory,
    DeletePurchaseCategory,
} from "~slices/PurchaseCategory";

import AccountingDetailPopup from "~/components/block/AccountingDetailPopup";

import { ReckoningPrice } from "~/components/functions/Reckoning";

const PurchaseCategory = () => {
    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <AddCategoryInput />
                <PurchaseCategoryTable />
            </Container>
        </React.Fragment>
    );
};

const AddCategoryInput = () => {
    const dispatch = useDispatch();
    const PurchaseCategoryState = useSelector((state) => state.PurchaseCategory);

    const HandleCreate = () => {
        if (!PurchaseCategoryState?.category_name) {
            alert("種別名は必須です。");
            return;
        }

        const params = {
            category_name: PurchaseCategoryState?.category_name,
            memo: PurchaseCategoryState?.memo,
            category_type: PurchaseCategoryState?.category_type,
        };
        dispatch(PurchaseCategory_set(["category_type", ""]));
        dispatch(PurchaseCategory_set(["category_name", ""]));
        dispatch(PurchaseCategory_set(["memo", ""]));

        dispatch(PostPurchaseCategory(params));
    };
    return (
        <>
            <Box sx={{ p: 2, mb: 2, border: "1px dashed grey" }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <FormControl sx={{ m: 1, width: "12rem" }}>
                        <InputLabel id="select-type-category_type" shrink>
                            タイプ
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="select-type-category_type"
                            label="タイプ"
                            value={PurchaseCategoryState?.category_type}
                            onChange={(e) => dispatch(PurchaseCategory_set(["category_type", e.target.value]))}
                        >
                            <MenuItem value={""}>未選択</MenuItem>
                            <MenuItem value={"固定費"}>固定費</MenuItem>
                            <MenuItem value={"仕入れ"}>仕入れ</MenuItem>
                            <MenuItem value={"人件費"}>人件費</MenuItem>
                            <MenuItem value={"交通費"}>交通費</MenuItem>
                            <MenuItem value={"雑費"}>雑費</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        sx={{ m: 1 }}
                        label="種別名"
                        value={PurchaseCategoryState?.category_name}
                        onChange={(e) => dispatch(PurchaseCategory_set(["category_name", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{ m: 1 }}
                        label="メモ"
                        value={PurchaseCategoryState?.memo}
                        onChange={(e) => dispatch(PurchaseCategory_set(["memo", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button onClick={HandleCreate} variant="contained" sx={{ height: "4rem", width: "6rem" }}>
                        登録
                    </Button>
                </Grid>
            </Box>
        </>
    );
};

const PurchaseCategoryTable = () => {
    const dispatch = useDispatch();
    const PurchaseCategoryState = useSelector((state) => state.PurchaseCategory);
    React.useEffect(() => {
        const params = {
            // type: type,
        };
        dispatch(GetPurchaseCategoryList(params)).then((data) => {
            console.log(data);
        });
    }, []);

    const columns = [
        { field: "purchase_category_id", headerName: "仕入れ種別ID", minWidth: 100, hide: true },
        { field: "category_name", headerName: "名前", minWidth: 100, flex: 1, hide: false },
        { field: "category_type", headerName: "タイプ", minWidth: 100, hide: false },
        { field: "memo", headerName: "メモ", minWidth: 100, flex: 1, hide: false },
        {
            field: "削除",
            headerName: "",
            sortable: false,
            width: 100,
            align: "right",
            // flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(DeletePurchaseCategory(params.row))}
                >
                    削除
                </Button>
            ),
        },
    ];

    return (
        <>
            <div style={{ height: 800, width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row.purchase_category_id}
                    rows={PurchaseCategoryState.purchase_category_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    disableSelectionOnClick
                    pagination
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>

            {/* <Button onClick={HandleDownload}>ダウンロード</Button>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計金額 {Number(total_amount).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計税金額 {Number(total_tax).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計手数料金額 {Number(total_commission).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計 繰り上げ・繰り下げ金額 {Number(total_round_amount).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計 利益額 {Number(total_amount - total_tax - total_commission).toLocaleString()}円
            </Grid> */}
        </>
    );
};
export default PurchaseCategory;
