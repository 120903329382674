import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    search_criteria: {
        end_time_end: "",
        end_time_start: "",
        clocking_out_flag: false,
        no_clocking_out_flag: false,
    },
    total_working_hours: 0,
    total_salary: 0,
    attendance_data: [],
    attendance_list: [],
    AttendanceCreateDialog: false,
    AttendanceDetailDialog: false,
};
export const GetAttendanceList = (arg) => {
    return AsyncFunction({
        type_name: "AttendanceList/勤怠一覧取得",
        method: "get",
        url: "/api/GetAttendanceList",
        params: arg,
    });
};
export const GetAttendance = (arg) => {
    return AsyncFunction({
        type_name: "Attendance/勤怠取得",
        method: "get",
        url: "/api/GetAttendance",
        params: arg,
    });
};

export const PostAttendance = (arg) => {
    return AsyncFunction({
        type_name: "Attendance/勤怠記入",
        method: "post",
        url: "/api/PostAttendance",
        params: arg,
    });
};
export const PostAttendanceAdmin = (arg) => {
    return AsyncFunction({
        type_name: "Attendance/勤怠作成",
        method: "post",
        url: "/api/PostAttendanceAdmin",
        params: arg,
    });
};
export const PutAttendance = (arg) => {
    return AsyncFunction({
        type_name: "Attendance/勤怠編集",
        method: "put",
        url: "/api/PutAttendance",
        params: arg,
    });
};

export const DeleteAttendance = (arg) => {
    return AsyncFunction({
        type_name: "Attendance/勤怠削除",
        method: "delete",
        url: "/api/DeleteAttendance",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Attendance",
    initialState,
    reducers: {
        Attendance_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Attendance") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.attendance_list) {
                    state.attendance_list = payload?.attendance_list;
                }
                if (payload?.total_working_hours) {
                    state.total_working_hours = payload?.total_working_hours;
                }
                if (payload?.total_salary) {
                    state.total_salary = payload?.total_salary;
                } else {
                    state.total_salary = 0;
                }
            }
        );
        // .addMatcher(
        //     (action) => action.type.match("AttendanceList") && action.type.endsWith("/fulfilled"),
        //     (state, action) => {
        //         const payload = action.payload;
        //         if (payload?.result != "OK") return;
        //         if (payload?.attendance_list) {
        //             state.attendance_list = payload?.attendance_list;
        //         }
        //     }
        // );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Attendance_set } = slice.actions;
