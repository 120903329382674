import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useDispatch, useSelector } from "react-redux";

export default function NaviDrawer(props) {
    const informationState = useSelector((state) => state.Information);
    const { open, onClose } = props;

    let links = [
        { label: "トップ", link: "/", icon: <MailIcon /> },
        { label: "新規登録", link: "/signup", icon: <MailIcon /> },
        { label: "ログイン", link: "/login", icon: <MailIcon /> },
    ];

    if (informationState.login_status == "user") {
        informationState.config.menu_view
            ? (links = [
                  { label: "トップ", link: "/", icon: <MailIcon /> },
                  { label: "ユーザー情報", link: "/UserInfo", icon: <InboxIcon /> },
                  { label: "メニュー", link: "/Menu", icon: <InboxIcon /> },
              ])
            : (links = [
                  { label: "トップ", link: "/", icon: <MailIcon /> },
                  { label: "ユーザー情報", link: "/UserInfo", icon: <InboxIcon /> },
              ]);
    }

    if (
        informationState.login_status == "user" &&
        (informationState.user_info.permission == "admin" || informationState.user_info.permission == "god")
    ) {
        informationState.config.menu_view
            ? (links = [
                  { label: "トップ", link: "/", icon: <MailIcon /> },
                  { label: "ユーザー情報", link: "/UserInfo", icon: <InboxIcon /> },
                  { label: "解析", link: "/Analysis", icon: <InboxIcon /> },
                  { label: "設定", link: "/Configuration", icon: <InboxIcon /> },
                  { label: "メニュー", link: "/Menu", icon: <InboxIcon /> },

                  // { label: "チャット", link: "/ChatList", icon: <InboxIcon /> },
              ])
            : (links = [
                  { label: "トップ", link: "/", icon: <MailIcon /> },
                  { label: "ユーザー情報", link: "/UserInfo", icon: <InboxIcon /> },
                  { label: "解析", link: "/Analysis", icon: <InboxIcon /> },
                  { label: "設定", link: "/Configuration", icon: <InboxIcon /> },
              ]);
    }

    const list = (anchor) => (
        <Box role="presentation">
            <List>
                {links.map((link_item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton component={Link} to={link_item?.link}>
                            <ListItemIcon>{link_item?.icon} </ListItemIcon>
                            <ListItemText primary={link_item?.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    return (
        <div>
            <Drawer anchor="left" open={open} onClose={onClose}>
                {list("left")}
            </Drawer>
        </div>
    );
}
