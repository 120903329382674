import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    client_list: [],
    client_data: {},
    OpenClientPopup: false,
};
export const GetClientList = (arg) => {
    return AsyncFunction({
        type_name: "Client/お客様一覧取得",
        method: "get",
        url: "/api/GetClientList",
        params: arg,
    });
};

export const PostClient = (arg) => {
    return AsyncFunction({
        type_name: "Client/お客様追加",
        method: "post",
        url: "/api/PostClient",
        params: arg,
    });
};

export const PutClient = (arg) => {
    return AsyncFunction({
        type_name: "Client/お客様編集",
        method: "put",
        url: "/api/PutClient",
        params: arg,
    });
};

export const DeleteClient = (arg) => {
    return AsyncFunction({
        type_name: "Client/お客様削除",
        method: "delete",
        url: "/api/DeleteClient",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Client",
    initialState,
    reducers: {
        Client_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Client") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.client_list) {
                    state.client_list = payload?.client_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Client_set } = slice.actions;
