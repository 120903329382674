import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    staff_list: [],
};
export const GetStaffList = (arg) => {
    return AsyncFunction({
        type_name: "Staff/スタッフ一覧取得",
        method: "get",
        url: "/api/GetStaffList",
        params: arg,
    });
};

export const PostStaff = (arg) => {
    return AsyncFunction({
        type_name: "Staff/スタッフ追加",
        method: "post",
        url: "/api/PostStaff",
        params: arg,
    });
};

export const DeleteStaff = (arg) => {
    return AsyncFunction({
        type_name: "Staff/スタッフ削除",
        method: "delete",
        url: "/api/DeleteStaff",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Staff",
    initialState,
    reducers: {
        Staff_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Staff") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.staff_list) {
                    state.staff_list = payload?.staff_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Staff_set } = slice.actions;
