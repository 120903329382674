import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Avatar from "@mui/material/Avatar";
import ButtonBase from "@mui/material/ButtonBase";
import Stack from "@mui/material/Stack";
import InputField from "~/components/inputs/InputField";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import EditIcon from "@mui/icons-material/Edit";
import ChatIcon from "@mui/icons-material/Chat";

import ReactImageBase64 from "react-image-base64";

import { GetUserInfo } from "~slices/User";
// import { GetTimeLine } from "~slices/TimeLine";

// import { OpenPopup } from "~slices/Popup";
import useConfirmationModalManagement from "~/components/functions/useConfirmationModalManagement";
import { confirmationModalActions } from "~slices/Popup";
import TimeLine from "~/components/block/TimeLine";

const User = () => {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const history = useHistory();

    const query = new URLSearchParams(search);
    const user_id = query.get("user_id");
    const UserState = useSelector((state) => state.User);
    const informationState = useSelector((state) => state.Information);
    const user_info = UserState.user_info;

    const [published, setpublished] = useState(false);

    const { open } = useConfirmationModalManagement();

    React.useEffect(() => {
        const params = {
            user_id: user_id,
        };
        dispatch(GetUserInfo(params)).then((data) => {
            // const serch_params = {
            //     serch_condition: JSON.stringify({
            //         user_id: user_id,
            //     }),
            // };
            // dispatch(GetTimeLine(serch_params));
        });
    }, []);

    // const OpenModal = () => {
    //     dispatch(OpenPopup()).then((data) => {
    //         console.log("open");
    //     });
    // };

    const OpenModal = async () => {
        // console.log(open);

        console.log("open");
        const isConfirmed = await open();

        console.log("isConfirmed");
        console.log(isConfirmed);
        // await dispatch(OpenPopup()).then((data) => {
        //     console.log("open");
        // });
    };

    return (
        <React.Fragment>
            {informationState?.login_status == "user" && informationState?.user_info?.user_id != user_id ? (
                <SpeedDial
                    onClick={() => history.push(`/Chat?destination=${user_id}`)}
                    ariaLabel="ChatStart"
                    sx={{ position: "fixed", bottom: 16, right: 16 }}
                    icon={<ChatIcon />}
                ></SpeedDial>
            ) : (
                ""
            )}
            <Container>
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                    {/* <Typography variant="h4" gutterBottom>
                            ユーザー情報
                        </Typography> */}

                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                        <label htmlFor="image_upload">
                            <Avatar
                                alt={user_info?.user_name}
                                src={user_info?.image_path}
                                sx={{ width: 150, height: 150 }}
                            ></Avatar>
                        </label>
                    </Stack>
                    {/* <Button onClick={OpenModal}>test</Button> */}
                    <Typography variant="h6">
                        {/* 名前 */}
                        {user_info?.user_name}
                    </Typography>

                    <Grid container sx={{ width: "90%", maxWidth: "350px" }}>
                        <Grid item xs>
                            <Typography align="left">自己紹介</Typography>
                        </Grid>
                        {informationState?.user_info?.user_id == user_info?.user_id ? (
                            <Grid item xs>
                                <Button onClick={() => history.push("/UserInfo")}>ユーザー情報変更</Button>
                            </Grid>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ border: "solid 1px", borderRadius: 2, p: 2, maxWidth: "350px", width: "90%" }}
                    >
                        <Typography>{user_info?.self_introduction}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default User;
