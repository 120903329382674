import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import ReactImageBase64 from "react-image-base64";

const UploadImage = (props) => {
    const { onChange, onReset, image, ...other } = props;
    return (
        <>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <label htmlFor="image_upload" style={{ height: "15.2rem", display: "flex", border: "solid 1px" }}>
                    <img
                        src={image ? image : "/images/NoImages/NoImage250_250.png"}
                        style={{ maxWidth: "15rem", maxHeight: "15rem", marginTop: "auto", marginBottom: "auto" }}
                    />
                </label>
                <div style={{ display: "none" }}>
                    <ReactImageBase64
                        maxFileSize={10485760}
                        thumbnail_size={500}
                        drop={false}
                        hidden={true}
                        // capture="environment"
                        multiple={true}
                        id="image_upload"
                        name="test"
                        handleChange={onChange}
                        {...other}
                    />
                </div>
                <Button onClick={onReset}>reset</Button>
            </Grid>
        </>
    );
};

export default UploadImage;
