import { combineReducers } from "redux";
import { Store, configureStore } from "@reduxjs/toolkit";

import Progress from "./slices/Progress";
import ErrorHandler from "./slices/ErrorHandler";
import Information from "./slices/Information";
import TimeLine from "./slices/Timeline";
import User from "./slices/User";
import Popup from "./slices/Popup";
import Chat from "./slices/Chat";
// SLY
import Check from "./slices/Check";
import Menu from "./slices/Menu";
import CheckHistory from "./slices/CheckHistory";
import Staff from "./slices/Staff";
import AccountingHistory from "./slices/AccountingHistory";
import Client from "./slices/Client";
import Attendance from "./slices/Attendance";
import Analysis from "./slices/Analysis";
import MenuType from "./slices/MenuType";
import Purchase from "./slices/Purchase";
import Image from "./slices/Image";
import PurchaseCategory from "./slices/PurchaseCategory";

const reducer = combineReducers({
    Progress: Progress,
    ErrorHandler: ErrorHandler,
    Information: Information,
    TimeLine: TimeLine,
    User: User,
    Popup: Popup,
    Chat: Chat,
    // SLY
    Menu: Menu,
    MenuType: MenuType,
    Check: Check,
    CheckHistory: CheckHistory,
    Staff: Staff,
    AccountingHistory: AccountingHistory,
    Client: Client,
    Attendance: Attendance,
    Analysis: Analysis,
    Purchase: Purchase,
    PurchaseCategory: PurchaseCategory,
    Image: Image,
});

const thunkArguments = {};
const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: thunkArguments,
            },
        }),
});
thunkArguments.store = store;

export default store;
