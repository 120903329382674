import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import SendIcon from "@mui/icons-material/Send";

import InputField from "~/components/inputs/InputField";

import ChatMessage from "~/components/block/ChatMessage";

import { PutPassword } from "~slices/Information";
import { GetChatList } from "~slices/Chat";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router";

const ChatList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const informationState = useSelector((state) => state.Information);
    const ChatState = useSelector((state) => state.Chat);

    const user_id = informationState?.user_info?.user_id;
    React.useEffect(() => {
        const params = {
            page: 1,
        };
        dispatch(GetChatList(params)).then((data) => {
            console.log(data);
        });
    }, []);

    return (
        <React.Fragment>
            <Container sx={{ minHeight: "100vh" }}>
                <List>
                    {ChatState?.chat_list?.map((chat_data, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        history.push(
                                            `/chat?destination=${
                                                chat_data?.user_id == user_id
                                                    ? chat_data.destination_user_id
                                                    : chat_data.user_id
                                            }`
                                        );
                                    }}
                                >
                                    <ListItemIcon>
                                        <Avatar
                                            alt={
                                                chat_data?.user_id == user_id
                                                    ? chat_data.destination_user_name
                                                    : chat_data.user_name
                                            }
                                            src={
                                                chat_data?.user_id == user_id
                                                    ? chat_data.destination_image_path
                                                    : chat_data.user_image_path
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            chat_data?.user_id == user_id
                                                ? chat_data.destination_user_name
                                                : chat_data.user_name
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {chat_data.text}
                                                </Typography>
                                                {/* {chat_data.created_at} */}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        </React.Fragment>
                    ))}
                </List>
            </Container>
        </React.Fragment>
    );
};

export default ChatList;
