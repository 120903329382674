import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    category_type: "",
    category_name: "",
    memo: "",
    purchase_category_list: [],
};
export const GetPurchaseCategoryList = (arg) => {
    return AsyncFunction({
        type_name: "CategoryPurchase/仕入種別一覧取得",
        method: "get",
        url: "/api/GetPurchaseCategoryList",
        params: arg,
    });
};

export const PostPurchaseCategory = (arg) => {
    return AsyncFunction({
        type_name: "CategoryPurchase/仕入種別追加",
        method: "post",
        url: "/api/PostPurchaseCategory",
        params: arg,
    });
};

export const DeletePurchaseCategory = (arg) => {
    return AsyncFunction({
        type_name: "CategoryPurchase/仕入種別削除",
        method: "delete",
        url: "/api/DeletePurchaseCategory",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "PurchaseCategory",
    initialState,
    reducers: {
        PurchaseCategory_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("CategoryPurchase") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.purchase_category_list) {
                        state.purchase_category_list = payload?.purchase_category_list;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.match("Purchase") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.purchase_category_list) {
                        state.purchase_category_list = payload?.purchase_category_list;
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { PurchaseCategory_set } = slice.actions;
