import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { GetStaffList } from "~slices/Staff";
import { Attendance_set, PostAttendance, GetAttendanceList, PutAttendance } from "~slices/Attendance";
import AttendanceTable from "~/components/block/AttendanceTable";
import AttendanceCreate from "~/components/block/AttendanceCreate";
import AttendanceDetailPopup from "~/components/block/AttendanceDetailPopup";
const AttendanceSet = () => {
    const dispatch = useDispatch();
    const AttendanceState = useSelector((state) => state.Attendance);

    const AttendanceCreateDialog = AttendanceState.AttendanceCreateDialog;
    const AttendanceDetailDialog = AttendanceState.AttendanceDetailDialog;
    const [Initialize, setInitialize] = React.useState(false);

    React.useEffect(() => {
        const search_criteria = {
            no_clocking_out_flag: true,
        };
        dispatch(Attendance_set(["search_criteria", search_criteria]));
        setInitialize(true);
    }, []);

    return (
        <React.Fragment>
            <Container sx={{ pt: 2, textAlign: "center" }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Button
                        variant="contained"
                        onClick={() => dispatch(Attendance_set(["AttendanceCreateDialog", true]))}
                        sx={{ height: "5rem", width: "10rem", mb: 1 }}
                        color="error"
                    >
                        出勤
                    </Button>
                </Grid>
                <Dialog
                    onClose={() => dispatch(Attendance_set(["AttendanceCreateDialog", false]))}
                    open={AttendanceCreateDialog}
                >
                    <AttendanceCreate />
                </Dialog>

                <Dialog
                    onClose={() => dispatch(Attendance_set(["AttendanceDetailDialog", false]))}
                    open={AttendanceDetailDialog}
                >
                    <AttendanceDetailPopup />
                </Dialog>

                {Initialize && <AttendanceTable />}
            </Container>
        </React.Fragment>
    );
};

export default AttendanceSet;
