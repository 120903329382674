import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    purchase_list: [],
    total_purchase: 0,
    filter: {
        year: new Date().getFullYear(),
        month: ("00" + (new Date().getMonth() + 1)).slice(-2),
        payment_methods: "",
        category_name: "",
        category_type: "",
    },
};
export const GetPurchaseList = (arg) => {
    return AsyncFunction({
        type_name: "Purchase/仕入れ種別一覧取得",
        method: "get",
        url: "/api/GetPurchaseList",
        params: arg,
    });
};

export const PostPurchase = (arg) => {
    return AsyncFunction({
        type_name: "Purchase/仕入れ種別追加",
        method: "post",
        url: "/api/PostPurchase",
        params: arg,
    });
};

export const DeletePurchase = (arg) => {
    return AsyncFunction({
        type_name: "Purchase/仕入れ種別削除",
        method: "delete",
        url: "/api/DeletePurchase",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Purchase",
    initialState,
    reducers: {
        Purchase_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Purchase") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.purchase_list) {
                    state.purchase_list = payload?.purchase_list;
                }
                if (payload?.total_purchase) {
                    state.total_purchase = payload?.total_purchase;
                } else {
                    state.total_purchase = 0;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Purchase_set } = slice.actions;
