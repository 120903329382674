import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Stateの初期状態
const initialState = {
    error_flag: false,
    error_message: "",
    message_title: "",
};

// Sliceを生成する
const slice = createSlice({
    name: "ErrorHandler",
    initialState,
    reducers: {
        errorHandler_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    console.debug("");
                    console.debug(
                        `▼▼▼▼▼▼▼｢%c${action.type}%c｣のリクエスト▼▼▼▼▼▼▼▼▼▼▼▼▼▼`,
                        "font-weight: bold; font-size: large; color: red;",
                        ""
                    );
                    console.debug(action.meta.arg);
                    console.debug("｢" + action.type + "｣の戻り値");
                    console.debug(payload);
                    console.debug(
                        `▲▲▲▲▲▲▲｢%c${action.type}%c｣の通信終了▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲`,
                        "font-weight: bold; font-size: large; color: red;",
                        ""
                    );
                    if (payload.result == "NG" && payload.message && !payload.disabled_message) {
                        state.error_flag = true;
                        state.error_message = payload.message;
                        if (payload?.message_title) {
                            state.message_title = payload.message_title;
                        } else {
                            state.message_title = "エラーが発生しました。";
                        }
                    }

                    if (payload.result == "OK" && payload.message && !payload.disabled_message) {
                        state.error_flag = true;
                        state.error_message = payload.message;
                        if (payload?.message_title) {
                            state.message_title = payload.message_title;
                        } else {
                            state.message_title = "";
                        }
                    }
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state, action) => {
                    // if (action.payload?.message && !action.payload.disabled_error) {
                    //     state.error_flag = true;
                    //     state.error_message = action.payload.message;
                    // } else {
                    state.error_flag = true;
                    state.error_message = "通信でエラーが発生しました。";
                    // }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { errorHandler_set } = slice.actions;
