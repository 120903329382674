import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";

const InputField = (props) => {
    const dispatch = useDispatch();
    const { value, onChange, ...other } = props;
    const [input_value, setInputValue] = React.useState(value ? value : "");

    useEffect(() => {
        setInputValue(value || value == 0 ? value : "");
    }, [value]);

    const handleChange = (e) => {
        setInputValue(e.target.value);
        onChange && onChange(e);
    };

    return (
        <React.Fragment>
            <TextField
                type="text"
                sx={{ mt: 1, mb: 1 }}
                InputLabelProps={{ required: false }}
                size="small"
                fullWidth
                value={input_value}
                onChange={handleChange}
                {...other}
            />
        </React.Fragment>
    );
};

export default InputField;
