import { format, parse, differenceInMinutes, getHours, subDays } from "date-fns";
import { ja } from "date-fns/locale";

/**
 * date_time "yyyy-MM-dd'T'HH:mm"を返す
 */
export const FormatTime = (time, type) => {
    // https://date-fns.org/v2.29.3/docs/format
    // return format(time, "MMM do (EEEEEE) Ho mo", { locale: ja });

    if (!time) return "";
    let str = "";
    switch (type) {
        case "date_time":
            str = "yyyy-MM-dd'T'HH:mm";
            break;
        case "date":
            str = "yyyy-MM-dd";
            break;
        case "time":
            str = "HH:mm";
            break;
        case "jp_date":
            str = "yyyy年MM月dd日";
            break;
        case "jp_date_time":
            str = "yyyy年MM月dd日HH時mm分";
            break;
        case "jp_time":
            str = "HH時mm分";
            break;

        default:
            str = "MMM do Ho mo";
            break;
    }

    return format(time, str, { locale: ja });
};

export const ParseTime = (time) => {
    if (!time) return "";

    let res_data = "";
    try {
        res_data = parse(time, "yyyy-MM-dd'T'HH:mm", new Date());
    } catch (e) {
        console.log(`｢${time}｣の変換に失敗しました。`);
        res_data = "";
    }
    return res_data;
    // return parse_res == "Invalid Date" ? "" : parse_res;
};

/**
 * 営業時間が日をまたぐ場合の対応。日を超えても開店日を表示する。
 */
export const FormatOpeningHours = (time, type) => {
    if (!time) return "";

    const hours = getHours(time);

    let str = "";
    switch (type) {
        case "date_time":
            str = "yyyy-MM-dd'T'HH:mm";
            break;
        case "date":
            str = "yyyy-MM-dd";
            break;
        default:
            str = "MM月dd日";
            break;
    }

    if (hours < 12) {
        const newdate = subDays(time, 1);
        return format(newdate, str);
    } else {
        return format(time, str);
    }

    // return parse_res == "Invalid Date" ? "" : parse_res;
};
