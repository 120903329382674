import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import SendIcon from "@mui/icons-material/Send";

import InputField from "~/components/inputs/InputField";

import ChatMessage from "~/components/block/ChatMessage";

import { PutPassword } from "~slices/Information";
import { GetChat, PostChat } from "~slices/Chat";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

const Chat = () => {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const destination = query.get("destination");
    const informationState = useSelector((state) => state.Information);
    const ChatState = useSelector((state) => state.Chat);

    // const HandleSubmit = (e) => {
    //     e.preventDefault();
    //     const elem = e.target.elements;

    //     const new_password = elem?.new_password?.value;
    //     const new_password_check = elem?.new_password_check?.value;

    //     if (new_password != new_password_check) {
    //         alert("パスワードと確認用パスワードが一致しません。");
    //         return;
    //     }
    //     const params = {
    //         old_password: elem?.old_password?.value,
    //         new_password: new_password,
    //     };
    //     console.log(params);

    //
    // };
    React.useEffect(() => {
        const params = {
            destination: destination,
        };
        dispatch(GetChat(params)).then((data) => {
            const chat_bottom = document.getElementById("chat_bottom");
            const content_position = chat_bottom.getBoundingClientRect();
            window.scrollTo(0, content_position.top);
        });
    }, []);

    const HandleSubmit = (e) => {
        e.preventDefault();
        const text = e.target.elements.text.value;
        const params = {
            text: text,
            destination: destination,
        };
        dispatch(PostChat(params)).then((data) => {
            document.chat_input_form.reset();
        });
    };

    const main_height = window.innerHeight - 100;

    return (
        <React.Fragment>
            <Container sx={{ minHeight: main_height }}>
                {ChatState?.chat_data?.map((message_data, index) => (
                    <React.Fragment key={index}>
                        <ChatMessage data={message_data} user_info={informationState?.user_info} />
                    </React.Fragment>
                ))}
                <div id="chat_bottom" style={{ marginBottom: "15rem" }} />
            </Container>
            <Box
                sx={{
                    width: "100%",
                    position: "sticky",
                    margin: "0",
                    bottom: "0",
                    left: "0",
                    zIndex: "1300",
                    background: "#fff",
                    padding: "0.1rem",
                }}
            >
                <form noValidate autoComplete="off" onSubmit={HandleSubmit} name="chat_input_form">
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs>
                            <TextField fullWidth size="small" multiline label={false} name="text" />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" sx={{ height: "2.5rem" }} type="submit">
                                {/* 送信 */}
                                <SendIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    );
};

export default Chat;
