import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { LinkPanel } from "~/components/block/LinkButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BuildIcon from "@mui/icons-material/Build";
import PeopleIcon from "@mui/icons-material/People";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Grid from "@mui/material/Grid";
const Top = () => {
    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);
    const permission = informationState.user_info.permission;
    return (
        <React.Fragment>
            <div>業務</div>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <LinkPanel label="電子伝票" icon={<BorderColorIcon style={{ fontSize: "80px" }} />} to="/Check" />

                <LinkPanel label="勤怠入力" icon={<ScheduleIcon style={{ fontSize: "80px" }} />} to="/AttendanceSet" />
            </Grid>{" "}
            {permission == "admin" || permission == "god" ? (
                <>
                    <div>履歴</div>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <LinkPanel
                            label="お会計履歴"
                            icon={<HistoryIcon style={{ fontSize: "80px" }} />}
                            to="/CheckHistory"
                        />
                        <LinkPanel
                            label="品目売上履歴"
                            icon={<LocalBarIcon style={{ fontSize: "80px" }} />}
                            to="/AccountingHistory"
                        />
                    </Grid>
                    <div>管理</div>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        {/* <LinkPanel label="設定" icon={<BuildIcon style={{ fontSize: "80px" }} />} to="/AdminPointSetting" /> */}

                        <LinkPanel
                            label="商品管理"
                            icon={<MenuBookIcon style={{ fontSize: "80px" }} />}
                            to="/MenuSetting"
                        />

                        <LinkPanel
                            label="お得意様管理"
                            icon={<PeopleIcon style={{ fontSize: "80px" }} />}
                            to="/ClientSetting"
                        />
                        <LinkPanel
                            label="スタッフ管理"
                            icon={<PermContactCalendarIcon style={{ fontSize: "80px" }} />}
                            to="/StaffSetting"
                        />
                        <LinkPanel
                            // disabled
                            label="勤怠確認"
                            icon={<EventAvailableIcon style={{ fontSize: "80px" }} />}
                            to="/AttendanceHistory"
                        />

                        <LinkPanel
                            // disabled
                            label="仕入れ"
                            icon={<ShoppingCartIcon style={{ fontSize: "80px" }} />}
                            to="/Purchase"
                        />
                        <LinkPanel
                            // disabled
                            label="解析"
                            icon={<QueryStatsIcon style={{ fontSize: "80px" }} />}
                            to="/Analysis"
                        />
                    </Grid>
                </>
            ) : (
                ""
            )}
            {permission == "god" ? (
                <>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <LinkPanel
                            // disabled
                            label="画像設定"
                            icon={<InsertPhotoIcon style={{ fontSize: "80px" }} />}
                            to="/ImageSetting"
                        />
                        <LinkPanel
                            // disabled
                            label="ユーザー設定"
                            icon={<AccountBoxIcon style={{ fontSize: "80px" }} />}
                            to="/UserList"
                        />
                    </Grid>
                </>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default Top;
