import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

const Footer = () => {
    return (
        <>
            <Grid
                className="hideInPrint"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ height: "4rem", mt: "1rem", background: "#f8fafc" }}
            ></Grid>
        </>
    );
};

export default Footer;
