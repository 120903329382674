import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { confirmationModalActions } from "~slices/Popup";

import useConfirmationModalManagement from "~/components/functions/useConfirmationModalManagement";

const Popup = () => {
    const { confirm, decline } = useConfirmationModalManagement();

    const PopupState = useSelector((state) => state.Popup);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(confirmationModalActions.Popup_set(["isOpened", false]));
    };

    const setDecline = () => {
        // return dispatch(decline());
    };

    return (
        <>
            {/* <Modal open={open}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "50%", background: "#fff" }}
                > */}
            <Dialog onClose={handleClose} open={PopupState?.isOpened}>
                test
                <Button onClick={confirm}>confirm</Button>
                <Button onClick={decline}>decline</Button>
            </Dialog>
            {/* </Grid>
            </Modal> */}
        </>
    );
};

export default Popup;
