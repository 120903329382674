import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";

const Menu = () => {
    const dispatch = useDispatch();
    // const CheckState = useSelector((state) => state.Check);

    return (
        <React.Fragment>
            <Container sx={{ pt: 2, textAlign: "center", background: "#000" }}>
                <img style={{ maxWidth: "100%", maxHeight: "90vh" }} src="/images/Menu/2023_04/menu_system.JPG" />
                <img style={{ maxWidth: "100%", maxHeight: "90vh" }} src="/images/Menu/2023_04/menu_nomiho.JPG" />
                <img style={{ maxWidth: "100%", maxHeight: "90vh" }} src="/images/Menu/2023_04/menu_champagne.JPG" />
                <img style={{ maxWidth: "100%", maxHeight: "90vh" }} src="/images/Menu/2023_04/menu_craftbeer2.JPG" />
                <img style={{ maxWidth: "100%", maxHeight: "90vh" }} src="/images/Menu/2023_04/menu_keep.JPG" />
            </Container>
        </React.Fragment>
    );
};

export default Menu;
