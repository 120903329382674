import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { GetStaffList } from "~slices/Staff";
import { Attendance_set, PostAttendance, GetAttendanceList, PutAttendance } from "~slices/Attendance";

const AttendanceTable = () => {
    const dispatch = useDispatch();
    const AttendanceState = useSelector((state) => state.Attendance);
    const attendance_list = AttendanceState.attendance_list;
    const informationState = useSelector((state) => state.Information);
    const permission = informationState.user_info.permission;
    React.useEffect(() => {
        const params = {
            ...AttendanceState.search_criteria,
        };
        dispatch(GetAttendanceList(params)).then((data) => {});
    }, []);

    const columns =
        permission == "admin" || permission == "god"
            ? [
                  { field: "attendance_id", headerName: "勤怠ID", minWidth: 100, hide: true },
                  { field: "staff_name", headerName: "名前", minWidth: 100, flex: 1, hide: false },
                  {
                      field: "start_time",
                      headerName: "出勤時間",
                      minWidth: 160,
                      hide: false,
                      valueGetter: (params) => FormatTime(ParseTime(params.row?.start_time)),
                  },
                  {
                      field: "end_time",
                      headerName: "退勤時間",
                      minWidth: 160,
                      hide: false,
                      valueGetter: (params) => FormatTime(ParseTime(params.row?.end_time)),
                  },
                  {
                      field: "working_hours",
                      headerName: "勤務時間",
                      minWidth: 70,
                      hide: false,
                      valueGetter: (params) =>
                          `${Math.floor(params.row?.working_hours / 60)}時間${params.row?.working_hours % 60}分`,
                  },
                  { field: "hourly", headerName: "時給", minWidth: 50, hide: false },
                  { field: "salary", headerName: "給与額", minWidth: 60, hide: false },
                  {
                      field: "pay_type",
                      headerName: "形態",
                      minWidth: 50,
                      hide: false,
                      valueGetter: (params) => {
                          switch (params.row?.pay_type) {
                              case "1":
                                  return "時給";
                              case "2":
                                  return "日給";
                              case "3":
                                  return "月給";
                              case "10":
                                  return "ボーナス";
                              case "20":
                                  return "手当";
                              case "99":
                                  return "その他";
                              default:
                                  return "";
                          }
                      },
                  },
                  { field: "memo", headerName: "メモ", minWidth: 100, flex: 1, hide: true },

                  {
                      field: "詳細",
                      headerName: "",
                      sortable: false,
                      width: 100,
                      align: "right",
                      // flex: 1,
                      renderCell: (params) => (
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                  dispatch(Attendance_set(["attendance_data", params.row]));
                                  dispatch(Attendance_set(["AttendanceDetailDialog", true]));
                              }}
                          >
                              詳細
                          </Button>
                      ),
                  },
              ]
            : [
                  { field: "attendance_id", headerName: "勤怠ID", minWidth: 100, hide: true },
                  { field: "staff_name", headerName: "名前", minWidth: 100, flex: 1, hide: false },
                  {
                      field: "start_time",
                      headerName: "出勤時間",
                      minWidth: 160,
                      hide: false,
                      valueGetter: (params) => FormatTime(ParseTime(params.row?.start_time)),
                  },
                  {
                      field: "詳細",
                      headerName: "",
                      sortable: false,
                      width: 100,
                      align: "right",
                      // flex: 1,
                      renderCell: (params) => (
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                  dispatch(Attendance_set(["attendance_data", params.row]));
                                  dispatch(Attendance_set(["AttendanceDetailDialog", true]));
                              }}
                          >
                              詳細
                          </Button>
                      ),
                  },
              ];

    return (
        <React.Fragment>
            <div style={{ height: 700, width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row.attendance_id}
                    rows={attendance_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    disableSelectionOnClick
                    pagination
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
        </React.Fragment>
    );
};
export default AttendanceTable;
