import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import InputField from "~/components/inputs/InputField";

import { UserSignup, PostUserOneTime } from "~slices/Information";

const Signup = () => {
    const dispatch = useDispatch();
    const [sended, setSended] = useState(false);

    const HandleGetOnetime = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        const email = elem?.email?.value;

        const params = {
            email: email,
        };
        dispatch(PostUserOneTime(params)).then((data) => {
            if (data.payload.result == "OK") {
                setSended(email);
            }
        });
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        if (elem?.password?.value !== elem?.password_again?.value) {
            alert("パスワードと確認用パスワードが一致しません");
            return;
        }

        const params = {
            onetime_password: elem?.onetime_password?.value,
            user_name: elem?.user_name?.value,
            email: sended,
            password: elem?.password?.value,
        };
        dispatch(UserSignup(params));
    };

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        新規登録
                    </Typography>

                    {sended ? (
                        // 送信後
                        <form onSubmit={HandleSubmit}>
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <InputField type="text" name="onetime_password" label="ワンタイムパスワード" required />
                                <InputField type="text" name="user_name" label="名前" required />
                                <InputField type="password" name="password" label="パスワード" required />
                                <InputField type="password" name="password_again" label="パスワード(確認)" required />
                                <Button variant="contained" type="submit">
                                    会員登録
                                </Button>
                            </Grid>
                        </form>
                    ) : (
                        // 送信前
                        <form onSubmit={HandleGetOnetime} autoComplete="off" name="onetime_form" key="1">
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <InputField type="email" name="email" label="メールアドレス" required />
                                新しいメールアドレスにワンタイムパスワードを送信します。
                                <Button variant="contained" type="submit">
                                    送信
                                </Button>
                            </Grid>
                        </form>
                    )}
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Signup;
