import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    chat_data: [],
    chat_list: [],
};
export const GetChatList = (arg) => {
    return AsyncFunction({
        type_name: "ChatList/チャット一覧取得",
        method: "get",
        url: "/api/GetChatList",
        params: arg,
    });
};
export const GetChat = (arg) => {
    return AsyncFunction({
        type_name: "Chat/チャット取得",
        method: "get",
        url: "/api/GetChat",
        params: arg,
    });
};

export const PostChat = (arg) => {
    return AsyncFunction({
        type_name: "Chat/チャット投稿",
        method: "post",
        url: "/api/PostChat",
        params: arg,
    });
};

export const DeleteChat = (arg) => {
    return AsyncFunction({
        type_name: "Chat/チャット削除",
        method: "delete",
        url: "/api/DeleteChat",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Chat",
    initialState,
    reducers: {
        Chat_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("Chat") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.chat_data) {
                        state.chat_data = payload?.chat_data;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.match("ChatList") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.chat_list) {
                        state.chat_list = payload?.chat_list;
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Chat_set } = slice.actions;
