// Reckoning
import { useSelector } from "react-redux";

// 一人あたり時間料金
export const SingleReckoning = (diff, config) => {
    const half_hour = Math.floor(diff / 30) + 1;

    const first_1_hour = config?.first_1_hour ? config.first_1_hour : 2500;
    const first_2_hour = config?.first_2_hour ? config.first_2_hour : 4500;
    const half_hour_price = config?.half_hour_price ? config.half_hour_price : 1000;
    const charge = config?.charge ? config.charge : 500;

    // 最初の1時間
    if (half_hour <= 2) {
        return first_1_hour;
    }
    // 最初の2時間
    if (half_hour <= 4) {
        return first_2_hour;
    }
//    let res = half_hour * half_hour_price + charge;

    let res = (half_hour - 4) * half_hour_price + Number(first_2_hour) + Number(charge);
//    if (diff % 30 == 0) {
//        res = (half_hour - 1) * half_hour_price + charge;
//    }

    return isNaN(res) ? 0 : res;
};

// 時間料金
export const ReckoningPrice = (params) => {
    const { number_people = 1, number_bottle_keep = 0, diff, config } = params;

    //時間料金制度ではない場合
    if (config?.time_price == false) {
        return 0;
    }

    const bottle_keep_price = config?.bottle_keep_price ? config.bottle_keep_price : 0;

    const bk_price = bottle_keep_price * number_bottle_keep;

    const no_bk_price = SingleReckoning(diff, config) * (number_people - number_bottle_keep);

    return bk_price + no_bk_price;
};
