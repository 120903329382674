import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { format, parse, differenceInMinutes } from "date-fns";
import { FormatTime, ParseTime, FormatOpeningHours } from "~/components/functions/DateTimeFunctions";

import { ja } from "date-fns/locale";
import {
    GetVisitList,
    Check_set,
    PostAccounting,
    GetCheckData,
    DeleteAccounting,
    PutVisit,
    DeleteVisit,
    PutAccounting,
} from "~slices/Check";

import AccountingDetailPopup from "~/components/block/AccountingDetailPopup";

import { styled } from "@mui/material/styles";
import { GetMenuList, Menu_set, PostMenu, DeleteMenu } from "~slices/Menu";
import { GetStaffList } from "~slices/Staff";

import { ReckoningPrice } from "~/components/functions/Reckoning";

const HeadGrid = styled(Grid)({
    p: 1,
    border: "1px solid grey",
    background: "#9bc6fa",
    // fontSize: "0.8rem",
    textAlign: "center",
});
const BodyGrid = styled(Grid)({
    border: "1px solid grey",
    // fontSize: "0.9rem",
    overflowWrap: "break-word",
});

// const FormatTime = (time, type) => {
//     // https://date-fns.org/v2.29.3/docs/format
//     // return format(time, "MMM do (EEEEEE) Ho mo", { locale: ja });

//     if (!time) return "";
//     let str = "";
//     switch (type) {
//         case "date_time":
//             str = "yyyy-MM-dd'T'HH:mm";
//             break;

//         default:
//             str = "MMM do Ho mo";
//             break;
//     }

//     return format(time, str, { locale: ja });
// };

// const ParseTime = (time) => {
//     if (!time) return "";
//     return parse(time, "yyyy-MM-dd'T'HH:mm", new Date());
//     // return parse_res == "Invalid Date" ? "" : parse_res;
// };

// const start = new Date(2022, 4, 1, 21, 30);
// const end = new Date(2022, 4, 2, 2, 56);
// const diff = differenceInMinutes(end, start);

const AccountingPopup = () => {
    const CheckState = useSelector((state) => state.Check);
    const accounting_list = CheckState.accounting_list;
    const visit_data = CheckState.visit_data;
    const informationState = useSelector((state) => state.Information);
    const permission = informationState.user_info.permission;
    const ConfigState = informationState.config;

    const dispatch = useDispatch();
    const [startTime, setStartTime] = React.useState(visit_data.visit_time);
    const [endTime, setEndTime] = React.useState("");
    const [BottleKeep, setBottleKeep] = React.useState(CheckState?.visit_data?.number_bottle_keep > 0 ? true : false);
    const [CraftBeer, setCraftBeer] = React.useState(false);

    const [changeName, setChangeName] = React.useState(false);
    const [newName, setNewName] = React.useState(
        CheckState?.visit_data?.client_name ? CheckState?.visit_data?.client_name : ""
    );
    const [memo, setMemo] = React.useState(CheckState?.visit_data?.memo ? CheckState?.visit_data?.memo : "");
    const [number_people, setNumberPeople] = React.useState(
        CheckState?.visit_data?.number_people ? CheckState?.visit_data?.number_people : 1
    );

    const [number_bottle_keep, setNumberBottleKeep] = React.useState(
        CheckState?.visit_data?.number_bottle_keep ? CheckState?.visit_data?.number_bottle_keep : 0
    );

    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        const params = {
            visit_id: visit_data.visit_id,
            page: 1,
        };

        dispatch(GetCheckData(params)).then((res) => {
            if (res.payload.result == "OK") {
                setOpen(true);
            }

            if (CheckState?.visit_data?.exit_time) {
                setEndTime(CheckState?.visit_data?.exit_time);
                return;
            }
            setEndTime(FormatTime(new Date(), "date_time"));
        });
    }, []);

    // const HandleSubmit = (e) => {
    //     e.preventDefault();
    //     const elem = e.target.elements;
    //     const params = {
    //         email: elem?.email?.value,
    //         password: elem?.password?.value,
    //     };
    //     console.log(params);

    //     dispatch(UserLogin(params));
    // };

    const Visit = () => {
        // console.log(FormatTime(new Date(), "date_time"));
        setStartTime(FormatTime(new Date(), "date_time"));
    };

    const Exit = () => {
        // console.log(FormatTime(new Date(), "date_time"));
        setEndTime(FormatTime(new Date(), "date_time"));
    };

    const start = ParseTime(startTime);
    const end = ParseTime(endTime);
    const diff = differenceInMinutes(end, start) - 1;
    const nomal_diff = differenceInMinutes(end, start);
    // 時間料金
    const Reckoning = () => {
        return ReckoningPrice({
            number_people: number_people,
            number_bottle_keep: number_bottle_keep,
            diff: diff,
            config: informationState.config,
        });
    };

    const handleBottleKeep = (event) => {
        if (event.target.checked) {
            setNumberBottleKeep(1);
            const params = {
                visit_id: CheckState?.visit_data?.visit_id,
                update_data: {
                    number_bottle_keep: 1,
                },
            };
            dispatch(PutVisit(params)).then((data) => {});
        } else {
            setNumberBottleKeep(0);
            const params = {
                visit_id: CheckState?.visit_data?.visit_id,
                update_data: {
                    number_bottle_keep: 0,
                },
            };
            dispatch(PutVisit(params)).then((data) => {});
        }

        setBottleKeep(event.target.checked);
    };
    const handleCraftBeer = (event) => {
        setCraftBeer(event.target.checked);
    };

    const handleNumberPeople = (event) => {
        console.log(event.target.value);
        const params = {
            visit_id: CheckState?.visit_data?.visit_id,
            update_data: {
                number_people: event.target.value,
            },
        };
        dispatch(PutVisit(params)).then((data) => {
            setChangeName(false);
            // dispatch(Check_set(["OpenAccountingPopup", false]));
            setNumberPeople(event.target.value);
        });
    };

    const handleNumberBottleKeep = (event) => {
        const params = {
            visit_id: CheckState?.visit_data?.visit_id,
            update_data: {
                number_bottle_keep: event.target.value,
            },
        };

        dispatch(PutVisit(params)).then((data) => {
            setChangeName(false);
            // dispatch(Check_set(["OpenAccountingPopup", false]));
            setNumberBottleKeep(event.target.value);
        });
    };

    let price = 0;
    accounting_list.map((accounting, index) => {
        price = Number(price) + Number(accounting.menu_price);
    });

    const OnAccounting = () => {
        // console.log(endTime);
        // console.log(ParseTime(endTime));
        // console.log(new Date());

        //現在時刻と退店時間の差分
        const diff_min = (ParseTime(endTime) - new Date()) / (1000 * 60);

        if (diff_min > 3 || diff_min < -3) {
            const res = window.confirm(
                `入力されている退店時間と${Math.ceil(diff_min)}分の差分があります。よろしいですか?`
            );
            if (!res) {
                return;
            }
        }

        // console.log(FormatTime(new Date(), "date_time"));
        dispatch(Check_set(["endTime", endTime]));
        dispatch(Check_set(["time_price", Reckoning()]));
        dispatch(Check_set(["stay_time", diff]));
        dispatch(Check_set(["OpenAccountingDetailPopup", true]));
    };

    const HandleStartTime = (e) => {
        const params = {
            visit_id: CheckState?.visit_data?.visit_id,
            update_data: {
                visit_time: e.target.value,
                business_day: FormatOpeningHours(ParseTime(e.target.value), "date"),
            },
        };

        dispatch(PutVisit(params));
        setStartTime(e.target.value);
    };

    const HandleEndTime = (e) => {
        setEndTime(e.target.value);
    };

    const HandleNameChange = (e) => {
        const params = {
            visit_id: CheckState?.visit_data?.visit_id,
            check_flag: 0,
            update_data: {
                client_name: newName,
                memo: memo,
            },
        };
        dispatch(PutVisit(params)).then((data) => {
            setChangeName(false);
            dispatch(Check_set(["OpenAccountingPopup", false]));
        });
    };

    const HandleDeleteAccounting = (e) => {
        const res = confirm(`削除しますか?`);
        if (!res) return;
        const params = {
            visit_id: CheckState?.visit_data?.visit_id,
            check: true,
        };
        dispatch(DeleteVisit(params)).then((data) => {
            setChangeName(false);
            dispatch(Check_set(["OpenAccountingPopup", false]));
        });
    };
    return (
        <React.Fragment>
            <AccountingDetailPopup />

            {open ? (
                <Container>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 1 }}>
                        <Typography variant="h4">{visit_data.client_name}様</Typography>

                        {changeName ? (
                            <Button
                                onClick={() => {
                                    setChangeName(false);
                                }}
                            >
                                閉じる
                            </Button>
                        ) : (
                            <>
                                <Button
                                    onClick={() => {
                                        setChangeName(true);
                                    }}
                                >
                                    情報変更
                                </Button>
                                <Grid container direction="row">
                                    {" "}
                                    {CheckState?.visit_data?.memo}
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {changeName && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ p: 1 }}
                        >
                            <TextField
                                sx={{ m: 1 }}
                                label="変更後お客様名"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                            <TextField
                                sx={{ m: 1 }}
                                label="メモ"
                                value={memo}
                                onChange={(e) => setMemo(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                rows={3}
                                fullWidth
                            />
                            <Button variant="contained" onClick={HandleNameChange}>
                                変更
                            </Button>
                        </Grid>
                    )}
                    {ConfigState.time_price && "時間料金"}
                    <Grid container sx={{ border: "solid 1px", p: 1, mt: 1, mb: 1 }}>
                        <Grid xs item>
                            <TextField
                                sx={{ m: 1 }}
                                label="来店時間"
                                type="datetime-local"
                                value={startTime}
                                onChange={HandleStartTime}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                来店時間
                            </TextField>
                            <TextField
                                sx={{ m: 1 }}
                                label="退店時間"
                                type="datetime-local"
                                value={endTime}
                                onChange={HandleEndTime}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                退店時間
                            </TextField>

                            {ConfigState.time_price && ConfigState.bottle_keep ? (
                                <FormControlLabel
                                    label="ボトルキープ料金"
                                    control={<Checkbox checked={BottleKeep} onChange={handleBottleKeep} />}
                                />
                            ) : (
                                ""
                            )}
                            <Grid container direction="row" justifyContent="space-around" alignItems="center">
                                <Grid item xs={6}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                        人数:
                                        <FormControl>
                                            <InputLabel id="select-number_people-label"></InputLabel>
                                            <Select
                                                sx={{ m: 1, width: "4rem" }}
                                                fullWidth
                                                labelId="select-number_people-label"
                                                // label="人数"
                                                value={Number(number_people)}
                                                onChange={handleNumberPeople}
                                            >
                                                {[...Array(10)].map((val, index) => (
                                                    <MenuItem value={index + 1} key={index}>
                                                        {index + 1}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        名様
                                    </Grid>{" "}
                                </Grid>
                                <Grid item xs={6}>
                                    {BottleKeep && (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-end"
                                        >
                                            ボトルキープ人数:
                                            <FormControl>
                                                <InputLabel id="select-number_BottleKeep-label">種別</InputLabel>
                                                <Select
                                                    sx={{ m: 1, width: "4rem" }}
                                                    fullWidth
                                                    labelId="select-number_BottleKeep-label"
                                                    // label="人数"
                                                    value={Number(number_bottle_keep)}
                                                    onChange={handleNumberBottleKeep}
                                                >
                                                    {[...Array(Number(number_people) + 1)].map((val, index) => (
                                                        <MenuItem value={index} key={index}>
                                                            {index}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            名様
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            {/* <FormControlLabel
                            label="クラフトビール飲み放題料金"
                            control={<Checkbox checked={CraftBeer} onChange={handleCraftBeer} />}
                        /> */}
                            {/* <div>{diff}分</div> */}
                            {ConfigState.time_price ? (
                                <>
                                    {!isNaN(diff) ? (
                                        <Grid container>
                                            <Grid xs={6} item>
                                                {Math.floor(nomal_diff / 60)}時間{nomal_diff % 60}分
                                                <br />
                                                税抜き{Reckoning()}円
                                            </Grid>
                                            <Grid xs={6} item>
                                                税込み
                                                {/* <div style={{ fontSize: "2rem", display: "inline" }}> */}
                                                {Number(Math.round(Reckoning() * 1.1)).toLocaleString()}
                                                {/* </div> */}円
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                        {/* <Grid item>
                        <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end">
                         
                            <Button variant="contained" onClick={Exit} sx={{ height: "5rem", width: "10rem" }}>
                                来店時間変更
                            </Button>
                        </Grid>
                    </Grid> */}
                    </Grid>
                    <AddAccounting ConfigState={ConfigState} />
                    {ConfigState.preparation ? (
                        <Grid sx={{ mb: 1 }}>
                            準備中メニュー
                            <PreparationTable />
                        </Grid>
                    ) : (
                        ""
                    )}
                    注文品一覧
                    <AccountingTable />
                    合計{Number(price + Reckoning()).toLocaleString()}円
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Grid>
                            税込み
                            <div style={{ fontSize: "2rem", display: "inline" }}>
                                {Number(Math.round((price + Reckoning()) * 1.1)).toLocaleString()}
                            </div>
                            円
                        </Grid>
                        <Grid>
                            {permission == "admin" || permission == "god" ? (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ height: "5rem", width: "5rem", mb: 1, mr: 1 }}
                                    onClick={HandleDeleteAccounting}
                                >
                                    削除
                                </Button>
                            ) : (
                                ""
                            )}

                            <Button
                                onClick={OnAccounting}
                                variant="contained"
                                color="error"
                                sx={{ height: "5rem", width: "10rem", mb: 1 }}
                            >
                                お会計
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};
const StatusLabel = (status) => {
    switch (status) {
        case 0:
            return "未選択";
        case 1:
            return "準備完了";
        case 2:
            return "準備中";
        default:
            return "未選択";
    }
};
const PreparationTable = (props) => {
    const CheckState = useSelector((state) => state.Check);
    const dispatch = useDispatch();

    const accounting_list = CheckState.accounting_list;
    const incomplete_list = accounting_list.filter((item) => item.status !== 1);

    const visit_data = CheckState.visit_data;

    const HandleChangeStatus = (e, accounting_data) => {
        const params = {
            visit_id: visit_data.visit_id,
            accounting_id: accounting_data.accounting_id,
            update_data: {
                status: e.target.value,
            },
        };
        dispatch(PutAccounting(params));
    };

    const HandleStatusAllSet = () => {
        const params = {
            visit_id: visit_data.visit_id,
            update_data: {
                status: 1,
            },
        };
        dispatch(PutAccounting(params));
    };

    return (
        <Grid container>
            <HeadGrid item xs={4}>
                品名
            </HeadGrid>
            <HeadGrid item xs={4}>
                注文時間
            </HeadGrid>
            <HeadGrid item xs={4}>
                ステータス
            </HeadGrid>

            {incomplete_list.map((item, index) => (
                <React.Fragment key={index}>
                    <BodyGrid item xs={4} sx={{ p: "4px" }}>
                        {item.menu_name}
                    </BodyGrid>
                    <BodyGrid item xs={4} sx={{ p: "4px", textAlign: "center", paddingTop: "0.8rem" }}>
                        {FormatTime(ParseTime(item.order_time), "jp_time")}
                    </BodyGrid>
                    <BodyGrid item xs={4} sx={{ p: "4px" }}>
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="select-status-label"></InputLabel>
                            <Select
                                fullWidth
                                labelId="select-status-label"
                                value={item.status}
                                size="small"
                                onChange={(e) => HandleChangeStatus(e, item)}
                            >
                                {/* <MenuItem value={1}>時給</MenuItem> */}
                                <MenuItem value={0}>未選択</MenuItem>
                                <MenuItem value={1}>完了</MenuItem>
                                <MenuItem value={2}>準備中</MenuItem>
                            </Select>
                        </FormControl>
                    </BodyGrid>
                </React.Fragment>
            ))}
            <Button onClick={HandleStatusAllSet}>すべて準備完了</Button>
        </Grid>
    );
};

const AccountingTable = (props) => {
    const CheckState = useSelector((state) => state.Check);
    const dispatch = useDispatch();
    const accounting_list = CheckState.accounting_list;
    const group = CheckState.accounting_list_group;

    // let price = 0;
    // accounting_list.map((accounting, index) => {
    //     price = Number(price) + Number(accounting.price);
    // });

    const AddItem = (item) => {
        console.log("item");
        console.log(item);
        const params = {
            visit_id: item.visit_id,
            type: item.type,
            menu_price: item.menu_price,
            menu_name: item.menu_name,
            menu_id: item.menu_id,
            staff_id: item.staff_id,
        };

        // dispatch(Check_set(["accounting_list", [...accounting_list, params]]));

        dispatch(PostAccounting(params));

        // dispatch(Check_set(["accounting_list", [...accounting_list, item]]));
    };

    const DellItem = (item) => {
        const res = confirm(`削除しますか?`);
        if (!res) return;
        const params = {
            accounting_id: item.accounting_id,
            visit_id: item.visit_id,
        };
        dispatch(DeleteAccounting(params));
        // dispatch(Check_set(["accounting_list", [...accounting_list, item]]));
    };

    return (
        <>
            <Grid container>
                <HeadGrid item xs={4}>
                    品名
                </HeadGrid>
                <HeadGrid item xs={2}>
                    単価
                </HeadGrid>
                <HeadGrid item xs={1}>
                    数
                </HeadGrid>
                <HeadGrid item xs={2}>
                    価格
                </HeadGrid>
                <HeadGrid item xs={1.5}></HeadGrid>
                <HeadGrid item xs={1.5}></HeadGrid>
                {group.map((item, index) => (
                    <React.Fragment key={index}>
                        <BodyGrid item xs={4} sx={{ p: "4px" }}>
                            {item.menu_name}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {item.menu_price}円
                        </BodyGrid>
                        <BodyGrid item xs={1} sx={{ textAlign: "center", pt: 1 }}>
                            {item.count}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {item.total_price}円
                        </BodyGrid>
                        <BodyGrid item xs={1.5} sx={{ textAlign: "right" }}>
                            <Button fullWidth onClick={() => AddItem(item)}>
                                追加
                            </Button>
                        </BodyGrid>
                        <BodyGrid item xs={1.5} sx={{ textAlign: "right" }}>
                            <Button fullWidth color="error" onClick={() => DellItem(item)}>
                                削除
                            </Button>
                        </BodyGrid>
                    </React.Fragment>
                ))}
                {/* 合計{price}円
                <Grid>
                    税込み
                    <div style={{ fontSize: "2rem", display: "inline" }}>{Math.floor(price * 1.1)}</div>円
                </Grid> */}
            </Grid>
        </>
    );
};

const AddAccounting = (props) => {
    const { ConfigState } = props;
    const MenuState = useSelector((state) => state.Menu);
    const StaffState = useSelector((state) => state.Staff);

    const [type, setType] = React.useState(
        ConfigState.default_type ? ConfigState.default_type : ConfigState.staff_drink ? 9 : 0
    );
    const [menu, setMenu] = React.useState(0);
    const [price, setPrice] = React.useState(1000);
    const [menu_name, setMenuName] = React.useState("");
    const [staff, setStaff] = React.useState(1);
    const dispatch = useDispatch();
    const CheckState = useSelector((state) => state.Check);
    const accounting_list = CheckState.accounting_list;

    const menu_list = MenuState.menu_list;
    // const menu_type_list = MenuState.menu_type_list;

    const staff_list = StaffState.staff_list;
    React.useEffect(() => {
        if (type != 9) {
            dispatch(GetMenuList({ type: type }));
        } else {
            dispatch(GetStaffList());
        }
    }, []);

    const HandleChangeType = (e) => {
        dispatch(GetMenuList({ type: e.target.value }));
        setType(e.target.value);
        setPrice(1000);
        setMenuName("");
        setMenu(0);
    };

    const HandleChangeMenu = (e) => {
        const select_menu_id = e.target.value;
        setMenu(select_menu_id);
        if (select_menu_id == 0) return;
        const menu_data = menu_list.filter((item) => item.menu_id == select_menu_id)[0];
        setPrice(menu_data.menu_price);
        setMenuName(menu_data.menu_name);
    };

    const HandleSetMenu = () => {
        let menu_label = menu_name;

        if (type != 9 && !menu_name && menu == 0) {
            alert("メニューが選択されていません");
            return;
        }
        if (type == 9) {
            const staff_data = staff_list.filter((item) => item.staff_id == staff)[0];
            menu_label = `スタッフドリンク(${staff_data.staff_name})`;
        }

        const params = {
            visit_id: CheckState.visit_data.visit_id,
            type: type,
            menu_price: price,
            price: price,
            menu_name: menu_label,
            menu_id: menu,
            staff_id: type == 9 ? staff : "",
        };

        // dispatch(Check_set(["accounting_list", [...accounting_list, params]]));

        dispatch(PostAccounting(params));
    };
    return (
        <Grid container sx={{ border: "solid 1px", p: 1, mt: 1, mb: 1 }}>
            <FormControl>
                <InputLabel id="select-type-label">種別</InputLabel>
                <Select
                    sx={{ m: 1, width: "12rem" }}
                    fullWidth
                    labelId="select-type-label"
                    label="種別"
                    value={type}
                    onChange={HandleChangeType}
                >
                    <MenuItem value={0}>その他</MenuItem>
                    {ConfigState.staff_drink && <MenuItem value={9}>スタッフドリンク</MenuItem>}
                    {MenuState.menu_type_list.map((val, index) => (
                        <MenuItem key={index} value={val.type}>
                            {val.menu_type_name}
                        </MenuItem>
                    ))}
                    {/* <MenuItem value={1}>一般ドリンク</MenuItem> */}
                    {/* <MenuItem value={2}>シャンパン</MenuItem>
                    <MenuItem value={3}>ボトル</MenuItem>
                    <MenuItem value={9}>スタッフドリンク</MenuItem> */}
                </Select>
            </FormControl>
            {type == 0 && (
                <TextField
                    sx={{ m: 1, width: "12rem" }}
                    label="名前"
                    value={menu_name}
                    onChange={(e) => setMenuName(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}

            {type == 9 && (
                <FormControl>
                    <InputLabel id="select-staff-label">スタッフ</InputLabel>
                    <Select
                        sx={{ m: 1, width: "12rem" }}
                        fullWidth
                        labelId="select-staff-label"
                        label="スタッフ"
                        value={staff}
                        onChange={(e) => setStaff(e.target.value)}
                    >
                        {staff_list.map((item, index) => (
                            <MenuItem key={index} value={item.staff_id}>
                                {item.staff_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            {type !== 0 && type != 9 && (
                <FormControl>
                    <InputLabel id="select-menu-label">メニュー</InputLabel>
                    <Select
                        sx={{ m: 1, width: "12rem" }}
                        fullWidth
                        labelId="select-menu-label"
                        label="メニュー"
                        value={menu}
                        onChange={HandleChangeMenu}
                    >
                        <MenuItem value={0}>未選択</MenuItem>
                        {menu_list.map((item, index) => (
                            <MenuItem key={index} value={item.menu_id}>
                                {item.menu_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <TextField
                sx={{ m: 1, width: "8rem" }}
                label="金額"
                type={"number"}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button
                variant="contained"
                sx={{ height: "5rem", width: "10rem", marginRight: "0", marginLeft: "auto" }}
                onClick={HandleSetMenu}
            >
                追加注文
            </Button>
        </Grid>
    );
};

export default AccountingPopup;
