import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { GetClientList, Client_set, PostClient, DeleteClient, PutClient } from "~slices/Client";

import { styled } from "@mui/material/styles";

import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

import { now } from "lodash";

const HeadGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // background: "#9bc6fa",
    // fontSize: "0.8rem",
    textAlign: "center",
    borderRadius: 2,
    fontWeight: "bold",
    fontSize: "1rem",
});
const BodyGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // fontSize: "0.9rem",
    overflowWrap: "break-word",
    borderRadius: 2,
    fontSize: "1rem",
});

const ClientSetting = () => {
    const dispatch = useDispatch();

    const [birthday, setBirthday] = React.useState("");
    const [client_name, setClientName] = React.useState("");
    React.useEffect(() => {
        dispatch(GetClientList()).then((data) => {
            console.log(data);
        });
    }, []);
    const CreateClient = () => {
        const params = {
            create_data: {
                birthday: birthday,
                client_name: client_name,
            },
        };
        dispatch(PostClient(params)).then((data) => {
            setBirthday("");
            setClientName("");
        });
    };

    // const HandleChangeType = (e) => {
    //     setType(e.target.value);

    //     const params = {
    //         type: e.target.value,
    //     };
    //     dispatch(GetClientList(params)).then((data) => {});
    // };
    return (
        <React.Fragment>
            <ClientPopup />
            <Container sx={{ pt: 2 }}>
                <Typography variant="h3" align="center" sx={{ pb: 2 }}>
                    お得意様管理
                </Typography>
                {/* <Grid>
                    <FormControl>
                        <InputLabel id="select-type-label">種別</InputLabel>
                        <Select
                            sx={{ m: 1, width: "12rem" }}
                            fullWidth
                            labelId="select-type-label"
                            label="種別"
                            value={type}
                            onChange={HandleChangeType}
                        >
                            <ClientItem value={2}>シャンパン</ClientItem>
                            <ClientItem value={3}>ボトル</ClientItem>
                        </Select>
                    </FormControl>
                </Grid> */}
                追加
                <Grid sx={{ border: "1px solid grey", p: 2 }} container direction="row" justifyContent="space-around">
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="名前"
                        value={client_name}
                        onChange={(e) => setClientName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{ m: 1, width: "10rem" }}
                        label="誕生日"
                        type="date"
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button variant="contained" sx={{ height: "4rem", width: "6rem" }} onClick={CreateClient}>
                        登録
                    </Button>
                </Grid>
                <ClientTable />
            </Container>
        </React.Fragment>
    );
};
const ClientPopup = () => {
    const ClientState = useSelector((state) => state.Client);
    const dispatch = useDispatch();
    const client_data = ClientState.client_data;

    const [client_name, setClientName] = React.useState("");
    const [birthday, setBirthday] = React.useState("");
    const [memo, setMemo] = React.useState("");

    React.useEffect(() => {
        setClientName(client_data.client_name);
        setBirthday(client_data.birthday ? client_data.birthday : "");
        setMemo(client_data.memo ? client_data.memo : "");
    }, [client_data]);

    const HandleSubmit = () => {
        const params = {
            client_id: client_data.client_id,
            update_data: {
                client_name: client_name,
                birthday: birthday,
                memo: memo,
            },
        };
        dispatch(PutClient(params));
        dispatch(Client_set(["OpenClientPopup", false]));
    };

    return (
        <Dialog
            onClose={() => dispatch(Client_set(["OpenClientPopup", false]))}
            open={ClientState.OpenClientPopup}
            sx={{ minWidth: "500px" }}
        >
            <Grid container direction="column" justifyContent="center" alignItems="flex-start" sx={{ p: 1, pr: 3 }}>
                <TextField
                    sx={{ m: 1 }}
                    label="お客様名"
                    value={client_name}
                    onChange={(e) => setClientName(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
                <TextField
                    sx={{ m: 1 }}
                    label="誕生日"
                    type="date"
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    sx={{ m: 1 }}
                    label="メモ"
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    multiline
                    rows={3}
                    fullWidth
                />
                <Button sx={{ m: 1 }} variant="contained" fullWidth onClick={HandleSubmit}>
                    変更
                </Button>
            </Grid>
        </Dialog>
    );
};

const ClientTable = (props) => {
    const ClientState = useSelector((state) => state.Client);
    const dispatch = useDispatch();
    const client_list = ClientState.client_list;
    const DellClient = (client_data) => {
        const res = confirm(`削除しますか?`);
        if (!res) return;

        dispatch(
            DeleteClient({
                client_id: client_data.client_id,
            })
        );
    };
    // const client_list = [{ client_name: "aaaa", manu_price: 1000 }];

    return (
        <>
            <Grid container sx={{ mt: 1 }}>
                <HeadGrid item xs={6}>
                    お客様名
                </HeadGrid>
                <HeadGrid item xs={2}>
                    誕生日
                </HeadGrid>
                <HeadGrid item xs={2}></HeadGrid>
                <HeadGrid item xs={2}></HeadGrid>

                {client_list.map((client, index) => (
                    <React.Fragment key={index}>
                        <BodyGrid item xs={6} sx={{ p: "4px" }}>
                            {client.client_name}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {client.birthday}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right" }}>
                            <Button
                                fullWidth
                                color="primary"
                                onClick={() => {
                                    dispatch(Client_set(["client_data", client]));
                                    dispatch(Client_set(["OpenClientPopup", true]));
                                }}
                            >
                                詳細
                            </Button>
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right" }}>
                            <Button fullWidth color="error" onClick={() => DellClient(client)}>
                                削除
                            </Button>
                        </BodyGrid>
                    </React.Fragment>
                ))}
                {/* 合計{price}円
                <Grid>
                    税込み
                    <div style={{ fontSize: "2rem", display: "inline" }}>{Math.floor(price * 1.1)}</div>円
                </Grid> */}
            </Grid>
        </>
    );
};

export default ClientSetting;
