import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";

import { GetImageList, PostImage, DeleteImage } from "~slices/Image";
import UploadImage from "~/components/block/UploadImage";

const ImageSetting = () => {
    const dispatch = useDispatch();
    const ImageState = useSelector((state) => state.Image);
    const [image, setImage] = useState("");
    const [big_image, setBigImage] = useState("");
    const [image_name, setImageName] = useState("");
    const [image_type, setImageType] = useState("ロゴ");
    const [memo, setMemo] = useState("");
    const [compression, setCompression] = useState(1);
    React.useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(GetImageList());
    }, []);

    const [selected_row, setSelectedRow] = useState(false);

    const HandleImageChange = (data) => {
        if (data.result) {
            //圧縮前データ
            setBigImage(data.ofileData);
            //圧縮データ
            setImage(data.fileData);
            //画像名
            const newFilename = data.fileName.replace(/\.[^/.]+$/, "");
            setImageName(newFilename);
        } else {
            setErrors([...errors, data.messages]);
        }
    };
    const HandleAddButton = () => {
        if (!image) return;
        const params = {
            create_data: {
                image: compression == 1 ? image : big_image,
                image_name: image_name,
                type: image_type,
                memo: memo,
            },
        };
        dispatch(PostImage(params)).then((data) => {
            setImageName("");
            setBigImage("");
            setImage("");
            setMemo("");
        });
    };

    const OpenImage = (row) => {
        setSelectedRow(row);
    };
    const image_type_list = [{ name: "ロゴ" }, { name: "メニュー" }, { name: "背景" }];

    const columns = [
        { field: "image_id", headerName: "画像ID", minWidth: 100, hide: true },
        { field: "image_path", headerName: "画像path", minWidth: 100, flex: 1, hide: true },

        {
            field: "image",
            headerName: "画像",
            sortable: false,
            width: 80,
            align: "right",
            // flex: 1,
            renderCell: (params) => (
                <div>
                    <img src={params.row.image_path} width="80px" />
                </div>
            ),
        },

        { field: "image_name", headerName: "名前", flex: 1, minWidth: 200, hide: false },
        { field: "type", headerName: "タイプ", minWidth: 100, hide: false },
        { field: "memo", headerName: "メモ", minWidth: 100, hide: false, flex: 1 },
        {
            field: "詳細",
            headerName: "",
            sortable: false,
            width: 80,
            align: "right",
            // flex: 1,
            renderCell: (params) => (
                <Button variant="contained" color="primary" onClick={() => OpenImage(params.row)}>
                    詳細
                </Button>
            ),
        },
        {
            field: "削除",
            headerName: "",
            sortable: false,
            width: 80,
            align: "right",
            // flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => dispatch(DeleteImage({ image_id: params.row.image_id }))}
                >
                    削除
                </Button>
            ),
        },
    ];

    return (
        <React.Fragment>
            {selected_row ? (
                <>
                    <Dialog onClose={() => setSelectedRow(false)} open={selected_row ? true : false}>
                        <DialogTitle>{selected_row.image_name}</DialogTitle>
                        <DialogContent>
                            <img src={selected_row.image_path} />
                        </DialogContent>
                        <DialogContent sx={{ width: "100%" }}>
                            画像Path
                            <DialogContentText sx={{ fontSize: "0.2rem" }}>{selected_row.image_path}</DialogContentText>
                            メモ
                            <DialogContentText>{selected_row.memo}</DialogContentText>
                        </DialogContent>
                    </Dialog>
                </>
            ) : (
                ""
            )}
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
                sx={{
                    p: 2,
                    border: "solid 1px lightgrey",
                    borderRadius: "0.3rem",
                    width: "96%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}
            >
                <Grid item>
                    <UploadImage onChange={HandleImageChange} onReset={() => setImage("")} image={image} />
                </Grid>
                <Grid item xs>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ p: 1 }}
                    >
                        <TextField
                            sx={{ m: 1 }}
                            label="画像名"
                            value={image_name}
                            onChange={(e) => setImageName(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <FormControl sx={{ m: 1, width: "12rem" }}>
                                <InputLabel id="select-image_type-label" shrink={true}>
                                    画像タイプ
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="select-image_type-label"
                                    label="画像タイプ"
                                    value={image_type}
                                    onChange={(e) => setImageType(e.target.value)}
                                >
                                    {image_type_list.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, width: "12rem" }}>
                                <InputLabel id="select-compression-label" shrink={true}>
                                    圧縮フラグ
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="select-compression-label"
                                    label="画像タイプ"
                                    value={compression}
                                    onChange={(e) => setCompression(e.target.value)}
                                >
                                    <MenuItem value={1}>圧縮</MenuItem>
                                    <MenuItem value={2}>元データ</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <TextField
                            sx={{ m: 1 }}
                            label="メモ"
                            multiline
                            rows={2}
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                        <Button variant="contained" color="primary" sx={{ width: "10rem" }} onClick={HandleAddButton}>
                            追加
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <div style={{ height: 800, width: "96%", marginLeft: "auto", marginRight: "auto" }}>
                <DataGrid
                    getRowId={(row) => row.image_id}
                    rows={ImageState.image_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    // rowClick={}
                    disableSelectionOnClick
                    pagination
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
        </React.Fragment>
    );
};
export default ImageSetting;
