import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";
import { FormatTime, ParseTime, FormatOpeningHours } from "~/components/functions/DateTimeFunctions";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    Dialog,
    FormControlLabel,
} from "@mui/material";

import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { format, parse, differenceInMinutes } from "date-fns";
import { ja } from "date-fns/locale";
import { GetVisitList, Check_set, PostAccounting, GetCheckData, PutVisit } from "~slices/Check";

import { styled } from "@mui/material/styles";
import { useHistory } from "react-router";

const HeadGrid = styled(Grid)({
    p: 1,
    borderBottom: "1px solid grey",
    // background: "#9bc6fa",
    // fontSize: "0.8rem",
    textAlign: "center",
});
const BodyGrid = styled(Grid)({
    borderBottom: "1px solid grey",
    // fontSize: "0.9rem",
    overflowWrap: "break-word",
});

const AccountingDetailPopup = (props) => {
    const { noncheck } = props;
    const CheckState = useSelector((state) => state.Check);
    const InformationState = useSelector((state) => state.Information);
    const config = InformationState.config;
    const dispatch = useDispatch();
    const history = useHistory();

    const [deposit, setDeposit] = React.useState(0);
    const [round, setRound] = React.useState(3);
    const [payment_methods, setPaymentMethods] = React.useState(
        CheckState?.visit_data?.payment_methods ? CheckState?.visit_data?.payment_methods : 0
    );

    React.useEffect(() => {
        setPaymentMethods(CheckState?.visit_data?.payment_methods);
        setDeposit(CheckState?.visit_data?.deposit ? CheckState?.visit_data?.deposit : 0);
        setRound(CheckState?.visit_data?.round ? CheckState?.visit_data?.round : 0);
    }, [CheckState?.visit_data]);

    const [no_change, setNoChange] = React.useState(false);

    let price = 0;
    CheckState.accounting_list.map((accounting, index) => {
        price = Number(price) + Number(accounting.menu_price);
    });

    // 税抜き合計
    const all_price = Number(price) + Number(CheckState.time_price);
    //キャッシュレス手数料割合
    const cache_res_commission = Number(config.cache_res_commission) / 100;
    // キャッシュレス手数料
    const tax = Math.round(Number(payment_methods != 0 ? all_price * cache_res_commission : 0));

    const all_price_intax = all_price + tax;

    const handleNoChange = (e) => {
        setNoChange(e.target.checked);
    };

    const RoundCeilFloor = (num) => {
        if (Math.floor(Number(num)) % 100 == 0) {
            return Math.floor(Number(num));
        }

        switch (round) {
            case 1:
                return Math.floor(Math.round(num) / 100) * 100;
            case 2:
                return Math.round(Math.round(num) / 100) * 100;
            case 3:
                return Math.ceil(Math.round(num) / 100) * 100;
            default:
                return Math.floor(Number(num));
        }
    };

    const including_tax = Math.floor(all_price_intax * 1.1);
    const rounded_including_tax = RoundCeilFloor(all_price_intax * 1.1);
    const consumption_tax = Math.floor(all_price_intax * 0.1);

    const HandleRound = (e) => {
        setRound(e.target.value);
    };
    const HandleJust = (e) => {
        setDeposit(rounded_including_tax);
    };
    const OnCheck = () => {
        let params_deposit = deposit;
        if (payment_methods == 0) {
            if (0 > deposit - rounded_including_tax) {
                alert("預かり金額が不足しています。");
                return;
            }
        } else {
            params_deposit = rounded_including_tax;
        }

        const change = payment_methods != 0 ? 0 : no_change ? 0 : deposit - including_tax;
        const amount = no_change ? deposit : rounded_including_tax;

        const params = {
            visit_id: CheckState?.visit_data?.visit_id,
            update_data: {
                exit_time: CheckState?.endTime,
                deposit: params_deposit,
                change: change,
                check_flag: 1,
                amount: amount,
                payment_methods: payment_methods,
                no_change: no_change ? 1 : 0,
                stay_time: CheckState?.stay_time,
                tax: consumption_tax,
                commission: tax,
                round_amount: rounded_including_tax - including_tax,
                round: round,
            },
        };

        dispatch(PutVisit(params)).then((data) => {
            dispatch(Check_set(["OpenAccountingDetailPopup", false]));
            dispatch(Check_set(["OpenAccountingPopup", false]));
            dispatch(Check_set(["visit_data", []]));

            if (data.payload.result == "OK" && config.receipt_confirmation) {
                let checkFlg = window.confirm("お会計が完了しました。\n領収書を表示しますか?");
                if (!checkFlg) return;
                history.push(`/Receipt?noNaviBar=true&visit_id=${CheckState?.visit_data?.visit_id}`);
            }
        });
    };

    const HandlePaymentMethods = (e) => {
        setPaymentMethods(e.target.value);
    };

    const background_image = config.accounting_detail_background;
    const logo_image = config.accounting_detail_logo;

    const HandleDownload = () => {
        const link = document.createElement("a");
        link.download = `download.pdf`;
        const logo = logo_image.startsWith("/") ? logo_image.slice(1) : logo_image;
        const url = "/DownloadReceiptDetail";
        const params = {
            logo: logo,
            visit_id: CheckState?.visit_data?.visit_id,
        };
        const encodedurl = url + "?" + new URLSearchParams(params);

        link.href = encodedurl;
        link.click();
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={CheckState.OpenAccountingDetailPopup}
                onClose={() => dispatch(Check_set(["OpenAccountingDetailPopup", false]))}
                sx={{ maxWidth: "none" }}
            >
                <div className="hideInPrint">
                    <Button
                        className="hideInPrint"
                        variant="contained"
                        onClick={() => dispatch(Check_set(["OpenAccountingDetailPopup", false]))}
                    >
                        閉じる
                    </Button>
                </div>
                {/* <div style={{  }}>aaaaaaaaaa</div> */}
                <Container
                    sx={{
                        pt: 2,
                        pb: 2,
                        backgroundImage: `url(${background_image})`,
                        backgroundSize: "100% 100%",
                        textAlign: "center",
                        minHeight: "80vh",
                        display: "grid",
                        display: "inline-table",
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ width: "60%", marginRight: "auto", marginLeft: "auto", pt: "3rem" }}
                    >
                        {CheckState?.visit_data?.client_name ? (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ pb: "2rem" }}
                            >
                                <Grid>
                                    {" "}
                                    <Typography align="left" variant="h5">
                                        {CheckState?.visit_data?.client_name}様
                                    </Typography>
                                    <Typography align="left" variant="p">
                                        {CheckState?.visit_data?.number_people}名様
                                    </Typography>
                                </Grid>
                                <Typography align="right" variant="p">
                                    {CheckState?.visit_data?.exit_time
                                        ? FormatTime(ParseTime(CheckState?.visit_data?.exit_time), "jp_date_time")
                                        : FormatTime(new Date(), "jp_date_time")}
                                </Typography>
                            </Grid>
                        ) : (
                            ""
                        )}
                        <Typography align="center" variant="h4">
                            お会計
                        </Typography>

                        <Grid container>
                            <HeadGrid item xs={6}>
                                品名
                            </HeadGrid>
                            <HeadGrid item xs={2} sx={{ textAlign: "right" }}>
                                単価
                            </HeadGrid>
                            <HeadGrid item xs={1} sx={{ textAlign: "right" }}>
                                数
                            </HeadGrid>
                            <HeadGrid item xs={3} sx={{ textAlign: "right" }}>
                                価格
                            </HeadGrid>
                            {config.time_price ? (
                                <>
                                    <BodyGrid item xs={6} sx={{ p: "4px" }}>
                                        時間料金
                                        {`(${CheckState?.visit_data?.number_people}名様 ${
                                            CheckState?.visit_data?.number_bottle_keep > 0
                                                ? `うちボトルキープ${CheckState?.visit_data?.number_bottle_keep}名様`
                                                : ""
                                        })`}
                                    </BodyGrid>
                                    <BodyGrid item xs={2} sx={{ p: "4px" }}></BodyGrid>
                                    <BodyGrid item xs={1} sx={{ p: "4px" }}></BodyGrid>
                                    <BodyGrid item xs={3} sx={{ p: "4px", textAlign: "right" }}>
                                        {Number(CheckState.time_price).toLocaleString()}円
                                    </BodyGrid>
                                </>
                            ) : (
                                ""
                            )}
                            {CheckState.accounting_list_group.map((accounting, index) => (
                                <React.Fragment key={index}>
                                    <BodyGrid item xs={6} sx={{ p: "4px" }}>
                                        {accounting.menu_name}
                                    </BodyGrid>
                                    <BodyGrid item xs={2} sx={{ p: "4px", textAlign: "right" }}>
                                        {Number(accounting.menu_price).toLocaleString()}円
                                    </BodyGrid>
                                    <BodyGrid item xs={1} sx={{ p: "4px", textAlign: "right" }}>
                                        {accounting.count}
                                    </BodyGrid>
                                    <BodyGrid item xs={3} sx={{ p: "4px", textAlign: "right" }}>
                                        {Number(accounting.total_price).toLocaleString()}円
                                    </BodyGrid>
                                </React.Fragment>
                            ))}

                            {/* キャッシュレス手数料 */}

                            {tax != 0 ? (
                                <>
                                    <BodyGrid item xs={6} sx={{ p: "4px" }}>
                                        サービス料
                                    </BodyGrid>
                                    <BodyGrid item xs={2} sx={{ p: "4px" }}></BodyGrid>
                                    <BodyGrid item xs={1} sx={{ p: "4px" }}></BodyGrid>
                                    <BodyGrid item xs={3} sx={{ p: "4px", textAlign: "right" }}>
                                        {tax}円
                                    </BodyGrid>
                                </>
                            ) : (
                                ""
                            )}

                            <BodyGrid item xs={9} sx={{ p: "4px", fontWeight: "bold" }}>
                                小計
                            </BodyGrid>
                            <BodyGrid item xs={3} sx={{ p: "4px", textAlign: "right" }}>
                                {Number(all_price_intax).toLocaleString()}円
                            </BodyGrid>

                            {/* 消費税手数料 */}
                            <BodyGrid item xs={6} sx={{ p: "4px" }}>
                                消費税
                            </BodyGrid>
                            <BodyGrid item xs={2} sx={{ p: "4px" }}></BodyGrid>
                            <BodyGrid item xs={1} sx={{ p: "4px" }}></BodyGrid>
                            <BodyGrid item xs={3} sx={{ p: "4px", textAlign: "right" }}>
                                {Math.floor(all_price_intax * 0.1)}円
                            </BodyGrid>

                            {/* {調整金額} */}
                            <BodyGrid item xs={6} sx={{ p: "4px" }}>
                                調整金額
                            </BodyGrid>
                            <BodyGrid item xs={2} sx={{ p: "4px" }}></BodyGrid>
                            <BodyGrid item xs={1} sx={{ p: "4px" }}></BodyGrid>
                            <BodyGrid item xs={3} sx={{ p: "4px", textAlign: "right" }}>
                                {rounded_including_tax - including_tax}円
                            </BodyGrid>
                        </Grid>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                            <Grid sx={{ marginLeft: "auto" }}>
                                税込み
                                <div style={{ fontSize: "2rem", display: "inline" }}>
                                    {rounded_including_tax.toLocaleString()}
                                </div>
                                円
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{ marginTop: "auto", display: "table-footer-group" }}>
                        <img
                            src={logo_image}
                            style={{ width: "50%", maxHeight: "300px", marginBottom: "1.2rem" }}
                            // style={{ objectFit: "cover", width: "480px", height: "270px" }}
                        />
                    </Grid>
                </Container>
                {noncheck ? (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        sx={{ marginTop: "auto", p: 2 }}
                        className="hideInPrint"
                    >
                        <Button
                            variant="contained"
                            onClick={() =>
                                history.push(`/Receipt?noNaviBar=true&visit_id=${CheckState?.visit_data?.visit_id}`)
                            }
                            color="error"
                            sx={{ height: "5rem", width: "10rem", mb: 1 }}
                        >
                            領収書
                        </Button>

                        <Button
                            variant="contained"
                            onClick={HandleDownload}
                            sx={{ height: "5rem", width: "10rem", mb: 1 }}
                        >
                            PDFダウンロード
                        </Button>
                    </Grid>
                ) : (
                    <Grid sx={{ marginTop: "auto", pt: 2 }} className="hideInPrint">
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                            <FormControl>
                                <InputLabel id="select-payment_methods-label">種別</InputLabel>
                                <Select
                                    sx={{ m: 1, width: "12rem" }}
                                    fullWidth
                                    labelId="select-payment_methods-label"
                                    label="お支払い方法"
                                    value={payment_methods}
                                    onChange={HandlePaymentMethods}
                                >
                                    <MenuItem value={0}>現金</MenuItem>
                                    <MenuItem value={1}>カード</MenuItem>
                                    <MenuItem value={2}>QR決済</MenuItem>
                                </Select>
                            </FormControl>
                            {payment_methods == 0 ? (
                                <>
                                    <TextField
                                        label="お預かり金額"
                                        type="number"
                                        onChange={(e) => setDeposit(e.target.value)}
                                        value={deposit}
                                        sx={{ p: 1 }}
                                    />

                                    <Button onClick={HandleJust} variant="outlined">
                                        金額自動入力
                                    </Button>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                        >
                                            <FormControlLabel
                                                label="お釣り不要"
                                                control={<Checkbox checked={no_change} onChange={handleNoChange} />}
                                            />

                                            <Grid item sx={{ fontSize: "large" }}>
                                                {0 > deposit - rounded_including_tax ? (
                                                    <>
                                                        不足金額
                                                        {Number(deposit - rounded_including_tax).toLocaleString()}円
                                                    </>
                                                ) : (
                                                    <>
                                                        お釣り{" "}
                                                        {Number(deposit - rounded_including_tax).toLocaleString()}円
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                ""
                            )}
                            <FormControl>
                                <InputLabel id="select-payment_methods-label">種別</InputLabel>
                                <Select
                                    sx={{ m: 1, width: "12rem" }}
                                    fullWidth
                                    labelId="select-round-label"
                                    label="お支払い方法"
                                    value={round}
                                    onChange={HandleRound}
                                >
                                    <MenuItem value={0}>そのまま</MenuItem>
                                    <MenuItem value={1}>繰り下げ</MenuItem>
                                    <MenuItem value={2}>四捨五入</MenuItem>
                                    <MenuItem value={3}>繰り上げ</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                onClick={OnCheck}
                                color="error"
                                sx={{ height: "5rem", width: "10rem", mb: 1, marginRight: "0", marginLeft: "auto" }}
                            >
                                会計
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Dialog>
        </React.Fragment>
    );
};

export default AccountingDetailPopup;
