import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

const Login = () => {
    const dispatch = useDispatch();

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;
        const params = {
            email: elem?.email?.value,
            password: elem?.password?.value,
        };
        console.log(params);

        dispatch(UserLogin(params));
    };

    return (
        <React.Fragment>
            <Container>
                <form onSubmit={HandleSubmit}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            ログイン
                        </Typography>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ border: "solid 1px", borderRadius: 2, p: 2, maxWidth: "350px", width: "80%" }}
                        >
                            <InputField type="email" name="email" label="メールアドレス" required />
                            <InputField type="password" name="password" label="パスワード" required />
                            <Button variant="contained" type="submit">
                                ログイン
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Link to="/signup">新規登録はこちら</Link>
                        {/* <Link to="/signup">パスワードを忘れた?</Link> */}
                    </Grid>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default Login;
