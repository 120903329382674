import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { GetStaffList } from "~slices/Staff";
import { Attendance_set, PostAttendanceAdmin, GetAttendanceList, PutAttendance } from "~slices/Attendance";
import AttendanceTable from "~/components/block/AttendanceTable";

const AttendanceCreateAdmin = () => {
    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);
    const AttendanceState = useSelector((state) => state.Attendance);

    const permission = informationState.user_info.permission;
    const attendance_data = AttendanceState.attendance_data;
    const StaffState = useSelector((state) => state.Staff);

    const [staff_id, setStaffId] = React.useState(0);
    const [pay_type, setPayType] = React.useState(2);
    const [salary, setSalary] = React.useState(attendance_data.salary);
    const [startTime, setStartTime] = React.useState(FormatTime(new Date(), "date"));
    const [endTime, setEnd_time] = React.useState(FormatTime(new Date(), "date"));

    // attendance_data.end_time ? attendance_data.end_time : FormatTime(new Date(), "date_time")

    React.useEffect(() => {
        dispatch(GetStaffList()).then((data) => {});
    }, []);

    const HandleStaffId = (e) => {
        setStaffId(e.target.value);
    };
    const onAttendance = () => {
        const staff_data = StaffState.staff_list.filter((item) => item.staff_id == staff_id)[0];
        let selected_staff_name = staff_data?.staff_name;
        if (!selected_staff_name) {
            selected_staff_name = window.prompt("名前を入力してください");
        }
        if (!selected_staff_name) {
            selected_staff_name = "名無し";
        }

        const params = {
            ...AttendanceState.search_criteria,
            create_data: {
                staff_id: staff_id,
                staff_name: staff_data?.staff_name ? staff_data.staff_name : selected_staff_name,
                hourly: 0,
                staff_id: staff_id,
                pay_type: pay_type,
                salary: salary,
                start_time: startTime,
                end_time: endTime,
            },
        };
        // console.log(params);
        dispatch(PostAttendanceAdmin(params)).then((data) => {
            dispatch(Attendance_set(["AttendanceCreateAdminDialog", false]));
        });
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "100%", p: 2 }}
        >
            <FormControl sx={{ m: 1, width: "13rem" }}>
                <InputLabel id="select-menu-label">従業員名</InputLabel>
                <Select
                    fullWidth
                    labelId="select-menu-label"
                    label="従業員名"
                    value={staff_id}
                    onChange={HandleStaffId}
                >
                    <MenuItem value={0}>未選択</MenuItem>
                    {StaffState.staff_list.map((item, index) => (
                        <MenuItem key={index} value={item.staff_id}>
                            {item.staff_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl sx={{ mt: 1, ml: 2, width: "13rem" }}>
                <InputLabel id="select-pay_type-label">給与形態</InputLabel>
                <Select
                    fullWidth
                    labelId="select-pay_type-label"
                    label="給与形態"
                    value={pay_type}
                    onChange={(e) => setPayType(e.target.value)}
                    disabled={permission == "admin" || permission == "god" ? false : true}
                >
                    {/* <MenuItem value={1}>時給</MenuItem> */}
                    <MenuItem value={2}>日給</MenuItem>
                    <MenuItem value={3}>月給</MenuItem>
                    <MenuItem value={10}>ボーナス</MenuItem>
                    <MenuItem value={20}>手当</MenuItem>
                    <MenuItem value={99}>その他</MenuItem>
                </Select>
            </FormControl>

            <TextField
                label="給与"
                variant="outlined"
                fullWidth
                type="number"
                sx={{ ml: 1, width: "13rem" }}
                value={salary ? salary : 0}
                onChange={(e) => setSalary(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">円</InputAdornment>,
                }}
                disabled={permission == "admin" || permission == "god" ? false : true}
            />

            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <TextField
                    sx={{ m: 1 }}
                    label="出勤時間"
                    type="date"
                    value={startTime}
                    onChange={(e) => {
                        setStartTime(e.target.value);
                        if (endTime < e.target.value) {
                            setEnd_time(e.target.value);
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={permission == "admin" || permission == "god" ? false : true}
                >
                    来店時間
                </TextField>
                <TextField
                    sx={{ m: 1 }}
                    label="退勤時間"
                    type="date"
                    value={endTime}
                    onChange={(e) => {
                        setEnd_time(e.target.value);
                        if (startTime > e.target.value) {
                            setStartTime(e.target.value);
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={permission == "admin" || permission == "god" ? false : true}
                >
                    退勤時間
                </TextField>
            </Grid>

            <Button onClick={onAttendance} variant="contained" sx={{ height: "3.5rem", width: "6rem" }}>
                追加
            </Button>
        </Grid>
    );
};
export default AttendanceCreateAdmin;
