import React from "react";
import { Link } from "react-router-dom";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    styled,
} from "@mui/material";

export const LinkButton = (props) => {
    const { to, label, children, ...other } = props;

    return (
        <Button variant="contained" color="primary" component={Link} to={to} {...other}>
            {children}
            {label}
        </Button>
    );
};
const LinkPanelBig = styled(LinkButton)({
    width: "300px",
    margin: "1%",
    height: "200px",
});

export const LinkPanel = (props) => {
    const { to, label, icon, width, ...other } = props;

    return (
        <>
            <LinkPanelBig
                label={
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        {props.label}
                        {icon}
                    </Grid>
                }
                to={to}
                {...other}
            />
        </>
    );
};
