import React, { useLayoutEffect, useEffect, useState } from "react";

import { Route, useLocation, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { green, orange, blue, purple, red, grey, brown, pink } from "@mui/material/colors";

import Top from "~/components/pages/Top";
import Login from "~/components/pages/Login";
import Signup from "~/components/pages/Signup";
import UserInfo from "~/components/pages/UserInfo";
import User from "~/components/pages/User";
import ChangePassword from "~/components/pages/ChangePassword";
import ChangeEmail from "~/components/pages/ChangeEmail";
import Chat from "~/components/pages/Chat";
import ChatList from "~/components/pages/ChatList";


import ErrerPopup from "~/components/popups/ErrerPopup";

import { TokenLogin, Information_set } from "~slices/Information";

import NaviBar from "~/components/block/NaviBar";
import Popup from "~/components/block/Popup";
import ModalProgress from "~/components/block/ModalProgress";
import Configuration from "~/components/pages/Configuration";

// SLY
import Check from "~/components/pages/Check";
import MenuSetting from "~/components/pages/MenuSetting";
import CheckHistory from "~/components/pages/CheckHistory";
import StaffSetting from "~/components/pages/StaffSetting";
import AccountingHistory from "~/components/pages/AccountingHistory";
import ClientSetting from "~/components/pages/ClientSetting";
import Menu from "~/components/pages/Menu";
import AttendanceSet from "~/components/pages/AttendanceSet";
import AttendanceHistory from "~/components/pages/AttendanceHistory";
import Analysis from "~/components/pages/Analysis";
import MenuTypeSetting from "~/components/pages/MenuTypeSetting";
import Purchase from "~/components/pages/Purchase";
import Footer from "~/components/block/Footer";
import Receipt from "~/components/pages/Receipt";
import Supplier from "~/components/pages/Supplier";
import PurchaseCategory from "~/components/pages/PurchaseCategory";
import ImageSetting from "~/components/pages/ImageSetting";
import UserList from "~/components/pages/UserList";



const App = (state) => {
    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);
    const config = informationState.config;
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    let theme_color;
    switch (config.theme_color) {
        case "red":
            theme_color = red[400];
            break;
        case "purple":
            theme_color = purple[400];
            break;
        case "orange":
            theme_color = orange[400];
            break;
        case "blue":
            theme_color = blue[400];
            break;
        case "grblackeen":
            theme_color = green[400];
            break;
        case "pink":
            theme_color = pink[400];
            break;
        case "brown":
            theme_color = brown[400];
            break;
        case "grey":
            theme_color = grey[900];
            break;
        default:
            theme_color = purple[400];
            break;
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: theme_color,
            },
            // mode: "dark",
            // secondary: {
            //     main: green[500],
            // },
        },
    });

    useLayoutEffect(() => {
        const user_token = localStorage.getItem("user_token");

        if (!user_token) {
            dispatch(Information_set(["login_status", "failure"]));
            return;
        }
        const params = {
            user_token: user_token,
        };
        dispatch(TokenLogin(params));
    }, []);

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Popup />
                {query.get("noNaviBar") ? "" : <NaviBar />}
                <ErrerPopup />
                <ModalProgress />
                <Switch>
                    {/* 共通 */}
                    <Route
                        path="/"
                        component={() => (
                            <>
                                <Top />
                            </>
                        )}
                        exact
                    />
                    <Route path="/Login" component={Login} exact />
                    <Route path="/Signup" component={Signup} exact />

                    {informationState?.login_status ? (
                        <>
                            <Route path="/UserInfo" component={UserInfo} exact />
                            <Route path="/User" component={User} exact />
                            <Route path="/ChangePassword" component={ChangePassword} exact />
                            <Route path="/ChangeEmail" component={ChangeEmail} exact />
                            <Route path="/Chat" component={Chat} exact />
                            <Route path="/ChatList" component={ChatList} exact />
                            <Route path="/Check" component={Check} exact />
                            <Route path="/CheckHistory" component={CheckHistory} exact />
                            <Route path="/MenuSetting" component={MenuSetting} exact />
                            <Route path="/MenuTypeSetting" component={MenuTypeSetting} exact />
                            <Route path="/StaffSetting" component={StaffSetting} exact />
                            <Route path="/AccountingHistory" component={AccountingHistory} exact />
                            <Route path="/ClientSetting" component={ClientSetting} exact />
                            <Route path="/Menu" component={Menu} exact />
                            <Route path="/AttendanceSet" component={AttendanceSet} exact />
                            <Route path="/AttendanceHistory" component={AttendanceHistory} exact />
                            <Route path="/Configuration" component={Configuration} exact />
                            <Route path="/Analysis" component={Analysis} exact />
                            <Route path="/Purchase" component={Purchase} exact />
                            <Route path="/Receipt" component={Receipt} exact />
                            <Route path="/Supplier" component={Supplier} exact />
                            <Route path="/PurchaseCategory" component={PurchaseCategory} exact />
                            <Route path="/UserList" component={UserList} exact />
                            <Route path="/ImageSetting" component={ImageSetting} exact />
                        </>
                    ) : (
                        ""
                    )}
                </Switch>
                <Footer />
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
