import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import InputField from "~/components/inputs/InputField";

import { PutPassword } from "~slices/Information";
import { Link } from "react-router-dom";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);
    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        const new_password = elem?.new_password?.value;
        const new_password_check = elem?.new_password_check?.value;

        if (new_password != new_password_check) {
            alert("パスワードと確認用パスワードが一致しません。");
            return;
        }
        const params = {
            old_password: elem?.old_password?.value,
            new_password: new_password,
        };
        console.log(params);

        dispatch(PutPassword(params));
    };

    return (
        <React.Fragment>
            <Container>
                <form onSubmit={HandleSubmit}>
                    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            パスワード変更
                        </Typography>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ border: "solid 1px", borderRadius: 2, p: 2, maxWidth: "350px", width: "90%" }}
                        >
                            <InputField type="password" name="old_password" label="現在のパスワード" required />
                            <InputField type="password" name="new_password" label="新しいパスワード" required />
                            <InputField
                                type="password"
                                name="new_password_check"
                                label="新しいパスワード(確認)"
                                required
                            />
                            <Button variant="contained" type="submit">
                                変更
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default ChangePassword;
