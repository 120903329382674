import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { GetStaffList } from "~slices/Staff";
import { Attendance_set, PostAttendance, GetAttendanceList, PutAttendance } from "~slices/Attendance";
import AttendanceTable from "~/components/block/AttendanceTable";
import AttendanceCreateAdmin from "~/components/block/AttendanceCreateAdmin";
import AttendanceDetailPopup from "~/components/block/AttendanceDetailPopup";
const AttendanceHistory = () => {
    const dispatch = useDispatch();
    const AttendanceState = useSelector((state) => state.Attendance);
    const InformationState = useSelector((state) => state.Information);
    const config = InformationState.config;
    const AttendanceCreateAdminDialog = AttendanceState.AttendanceCreateAdminDialog;
    const AttendanceDetailDialog = AttendanceState.AttendanceDetailDialog;

    const [Initialize, setInitialize] = React.useState(false);

    React.useEffect(() => {
        const attendance_deadline = config.attendance_deadline ? config.attendance_deadline : 1;
        let date = new Date();
        const today = date.getDate();

        if (today < attendance_deadline) {
            date.setMonth(date.getMonth() - 1);
        }
        date.setDate(attendance_deadline);
        date.setHours(12);
        date.setMinutes(0);
        const month_beginning = FormatTime(date, "date_time");
        const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");

        const search_criteria = {
            clocking_out_flag: true,
            end_time_start: month_beginning,
            end_time_end: next_month_beginning,
        };
        dispatch(GetStaffList());
        dispatch(Attendance_set(["search_criteria", search_criteria]));
        setInitialize(true);
    }, []);

    return (
        <React.Fragment>
            <Container sx={{ pt: 2, textAlign: "center" }}>
                <SearchCriteria />
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Button
                        variant="contained"
                        onClick={() => dispatch(Attendance_set(["AttendanceCreateAdminDialog", true]))}
                        sx={{ height: "5rem", width: "10rem", mb: 1 }}
                    >
                        追加
                    </Button>
                </Grid>
                <Dialog
                    onClose={() => dispatch(Attendance_set(["AttendanceCreateAdminDialog", false]))}
                    open={AttendanceCreateAdminDialog}
                >
                    <AttendanceCreateAdmin />
                </Dialog>
                <Dialog
                    onClose={() => dispatch(Attendance_set(["AttendanceDetailDialog", false]))}
                    open={AttendanceDetailDialog}
                >
                    <AttendanceDetailPopup />
                </Dialog>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                    {AttendanceState?.total_working_hours ? (
                        <>
                            <Box>
                                合計勤務時間 {Math.floor(AttendanceState.total_working_hours / 60)}時間
                                {AttendanceState.total_working_hours % 60}分
                            </Box>
                            <Box sx={{ ml: 5 }}>合計給与額 {AttendanceState.total_salary}円</Box>
                        </>
                    ) : (
                        ""
                    )}
                </Grid>
                {Initialize && <AttendanceTable />}
            </Container>
        </React.Fragment>
    );
};

const SearchCriteria = () => {
    const dispatch = useDispatch();
    const AttendanceState = useSelector((state) => state.Attendance);
    const StaffState = useSelector((state) => state.Staff);
    const sc = AttendanceState?.search_criteria;

    const HandleSerch = () => {
        dispatch(GetAttendanceList(sc));
    };
    const SubMonth = () => {
        // const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");
        dispatch(
            Attendance_set([
                "search_criteria",
                {
                    ...sc,
                    end_time_end: FormatTime(
                        ParseTime(sc.end_time_end).setMonth(ParseTime(sc.end_time_end).getMonth() - 1),
                        "date_time"
                    ),
                    end_time_start: FormatTime(
                        ParseTime(sc.end_time_start).setMonth(ParseTime(sc.end_time_start).getMonth() - 1),
                        "date_time"
                    ),
                },
            ])
        );
    };
    const AddMonth = () => {
        // const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");
        dispatch(
            Attendance_set([
                "search_criteria",
                {
                    ...sc,
                    end_time_start: FormatTime(
                        ParseTime(sc.end_time_start).setMonth(ParseTime(sc.end_time_start).getMonth() + 1),
                        "date_time"
                    ),
                    end_time_end: FormatTime(
                        ParseTime(sc.end_time_end).setMonth(ParseTime(sc.end_time_end).getMonth() + 1),
                        "date_time"
                    ),
                },
            ])
        );
    };

    return (
        <>
            <Box sx={{ p: 2, mb: 2, border: "1px dashed grey" }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <TextField
                        sx={{ m: 1 }}
                        label="出勤時間"
                        type="datetime-local"
                        value={sc.end_time_start ? sc.end_time_start : ""}
                        onChange={(e) =>
                            dispatch(Attendance_set(["search_criteria", { ...sc, end_time_start: e.target.value }]))
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    ～
                    <TextField
                        sx={{ m: 1 }}
                        label="出勤時間"
                        type="datetime-local"
                        value={sc.end_time_end ? sc.end_time_end : ""}
                        onChange={(e) =>
                            dispatch(Attendance_set(["search_criteria", { ...sc, end_time_end: e.target.value }]))
                        }
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Grid container direction="column" sx={{ width: "6rem" }}>
                        <Button onClick={SubMonth} variant="contained" sx={{ height: "1.5rem", width: "6rem" }}>
                            △先月
                        </Button>
                        <Button onClick={AddMonth} variant="contained" sx={{ height: "1.5rem", width: "6rem" }}>
                            ▽次月
                        </Button>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <FormControl sx={{ m: 1, width: "12rem" }}>
                        <InputLabel id="select-staff_name-label">スタッフ名</InputLabel>
                        <Select
                            fullWidth
                            labelId="select-staff_name-label"
                            value={sc.staff_name}
                            label="スタッフ名"
                            onChange={(e) =>
                                dispatch(Attendance_set(["search_criteria", { ...sc, staff_name: e.target.value }]))
                            }
                        >
                            <MenuItem value={""}>すべて</MenuItem>
                            {StaffState.staff_list.map((staff, index) => (
                                <MenuItem value={staff.staff_name} key={index}>
                                    {staff.staff_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button onClick={HandleSerch} variant="contained" sx={{ height: "4rem", width: "6rem" }}>
                        検索
                    </Button>
                </Grid>
            </Box>
        </>
    );
};

export default AttendanceHistory;
