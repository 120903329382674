import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { format, parse, differenceInMinutes, subDays } from "date-fns";
import {
    Container,
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    styled,
} from "@mui/material";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";
import { CheckHistory_set, SerchVisit, DeleteVisit, PutVisit, DownloadVisit } from "~slices/CheckHistory";
import AccountingPopup from "~/components/block/AccountingPopup";
import { FormatTime, ParseTime, FormatOpeningHours } from "~/components/functions/DateTimeFunctions";
import { GetVisitList, Check_set, PostAccounting, GetCheckData, DeleteAccounting } from "~slices/Check";

import AccountingDetailPopup from "~/components/block/AccountingDetailPopup";

import { ReckoningPrice } from "~/components/functions/Reckoning";
import { azAZ } from "@mui/material/locale";

const Supplier = () => {
    // const CheckHistoryState = useSelector((state) => state.CheckHistory);
    React.useEffect(() => {
        const params = {
            // type: type,
        };
        // dispatch(GetMenuList(params)).then((data) => {
        //     console.log(data);
        // });
    }, []);

    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                ddd
                {/* <SearchCriteria />
                <VisitTable /> */}
            </Container>
        </React.Fragment>
    );
};

const SearchCriteria = () => {
    const dispatch = useDispatch();
    const CheckHistoryState = useSelector((state) => state.CheckHistory);
    const exit_time_start = CheckHistoryState.exit_time_start;
    const exit_time_end = CheckHistoryState.exit_time_end;
    const client_name = CheckHistoryState.client_name;
    const payment_methods = CheckHistoryState.payment_methods;

    React.useEffect(() => {
        HandleSerch();
    }, []);

    const HandleSerch = () => {
        const params = {
            payment_methods: payment_methods,
            exit_time_start: exit_time_start,
            exit_time_end: exit_time_end,
            check_flag: 1,
            client_name: client_name,
        };
        dispatch(SerchVisit(params));
    };

    const ParseTime = (time) => {
        if (!time) return "";
        return parse(time, "yyyy-MM-dd'T'HH:mm", new Date());
        // return parse_res == "Invalid Date" ? "" : parse_res;
    };

    const SubMonth = () => {
        // const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");
        dispatch(
            CheckHistory_set([
                "exit_time_start",
                FormatTime(ParseTime(exit_time_start).setMonth(ParseTime(exit_time_start).getMonth() - 1), "date_time"),
            ])
        );
        dispatch(
            CheckHistory_set([
                "exit_time_end",
                FormatTime(ParseTime(exit_time_end).setMonth(ParseTime(exit_time_end).getMonth() - 1), "date_time"),
            ])
        );
    };
    const AddMonth = () => {
        // const next_month_beginning = FormatTime(date.setMonth(date.getMonth() + 1), "date_time");
        dispatch(
            CheckHistory_set([
                "exit_time_start",
                FormatTime(ParseTime(exit_time_start).setMonth(ParseTime(exit_time_start).getMonth() + 1), "date_time"),
            ])
        );
        dispatch(
            CheckHistory_set([
                "exit_time_end",
                FormatTime(ParseTime(exit_time_end).setMonth(ParseTime(exit_time_end).getMonth() + 1), "date_time"),
            ])
        );
    };
    return (
        <>
            <AccountingDetailPopup noncheck />
            <Box sx={{ p: 2, mb: 2, border: "1px dashed grey" }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <TextField
                        sx={{ m: 1 }}
                        label="会計時間"
                        type="datetime-local"
                        value={exit_time_start}
                        onChange={(e) => dispatch(CheckHistory_set(["exit_time_start", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    ～
                    <TextField
                        sx={{ m: 1 }}
                        label="会計時間"
                        type="datetime-local"
                        value={exit_time_end}
                        onChange={(e) => dispatch(CheckHistory_set(["exit_time_end", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Grid container direction="column" sx={{ width: "6rem" }}>
                        <Button onClick={SubMonth} variant="contained" sx={{ height: "1.5rem", width: "6rem" }}>
                            △先月
                        </Button>
                        <Button onClick={AddMonth} variant="contained" sx={{ height: "1.5rem", width: "6rem" }}>
                            ▽次月
                        </Button>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <TextField
                        sx={{ m: 1 }}
                        label="お客様名"
                        value={client_name}
                        onChange={(e) => dispatch(CheckHistory_set(["client_name", e.target.value]))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <FormControl>
                        <InputLabel id="select-payment_methods-label">お支払い方法</InputLabel>
                        <Select
                            sx={{ m: 1, width: "12rem" }}
                            fullWidth
                            labelId="select-payment_methods-label"
                            label="お支払い方法"
                            value={payment_methods}
                            onChange={(e) => dispatch(CheckHistory_set(["payment_methods", e.target.value]))}
                        >
                            <MenuItem value={""}>すべて</MenuItem>
                            <MenuItem value={"0"}>現金</MenuItem>
                            <MenuItem value={"1"}>カード</MenuItem>
                            <MenuItem value={"2"}>QR決済</MenuItem>
                            <MenuItem value={"no_cash"}>現金以外</MenuItem>
                        </Select>
                    </FormControl>

                    <Button onClick={HandleSerch} variant="contained" sx={{ height: "4rem", width: "6rem" }}>
                        検索
                    </Button>
                </Grid>
            </Box>
        </>
    );
};

const SupplierTable = () => {
    const dispatch = useDispatch();
    const CheckHistoryState = useSelector((state) => state.CheckHistory);
    const informationState = useSelector((state) => state.Information);

    const check_data = CheckHistoryState.check_data;
    const payment_methods = CheckHistoryState.payment_methods;

    const total_amount = CheckHistoryState.total_amount;
    const total_tax = CheckHistoryState.total_tax;
    const total_commission = CheckHistoryState.total_commission;
    const total_round_amount = CheckHistoryState.total_round_amount;

    const exit_time_start = CheckHistoryState.exit_time_start;
    const exit_time_end = CheckHistoryState.exit_time_end;
    const client_name = CheckHistoryState.client_name;

    const HandleDownload = () => {
        const params = {
            payment_methods: payment_methods,
            exit_time_start: exit_time_start,
            exit_time_end: exit_time_end,
            check_flag: 1,
            client_name: client_name,
        };
        dispatch(DownloadVisit(params));
    };

    // const OnDeleteVisit = (row) => {
    //     const res = confirm(`削除しますか?`);
    //     if (!res) return;
    //     const params = {
    //         visit_id: row.visit_id,
    //         exit_time_start: exit_time_start,
    //         exit_time_end: exit_time_end,
    //         check_flag: 1,
    //         client_name: client_name,
    //     };
    //     dispatch(DeleteVisit(params));
    // };

    const DiffTime = (row) => {
        const start = ParseTime(row.visit_time);
        const end = ParseTime(row.exit_time);
        const diff = differenceInMinutes(end, start) - 1;
        return diff;
    };
    const OpenAccountingDetailPopup = (row) => {
        const params = {
            visit_id: row.visit_id,
            page: 1,
        };

        const diff = DiffTime(row);

        // 時間料金
        const Reckoning = ReckoningPrice({
            number_people: row.number_people,
            number_bottle_keep: row.number_bottle_keep,
            diff: diff,
            config: informationState.config,
        });
        dispatch(GetCheckData(params)).then((data) => {
            dispatch(Check_set(["visit_data", row]));
            dispatch(Check_set(["time_price", Reckoning]));
            dispatch(Check_set(["OpenAccountingDetailPopup", true]));
        });
    };

    const columns = [
        { field: "visit_id", headerName: "来店ID", minWidth: 100, hide: true },
        { field: "client_name", headerName: "名前", minWidth: 100, flex: 1, hide: false },
        {
            field: "日付",
            headerName: "日付",
            minWidth: 100,
            hide: false,
            valueGetter: (params) => FormatOpeningHours(ParseTime(params.row?.visit_time)),
        },
        {
            field: "visit_time",
            headerName: "来店時間",
            minWidth: 100,

            hide: false,
            valueGetter: (params) => FormatTime(ParseTime(params.row?.visit_time), "time"),
        },
        {
            field: "exit_time",
            headerName: "退店時間",
            minWidth: 100,
            hide: false,
            valueGetter: (params) => FormatTime(ParseTime(params?.row?.exit_time), "time"),
        },

        {
            field: "滞在時間",
            headerName: "滞在時間",
            minWidth: 40,
            hide: false,
            valueGetter: (params) =>
                `${Math.floor((DiffTime(params?.row) + 1) / 60)}時間${(DiffTime(params?.row) + 1) % 60}分`,
        },

        { field: "deposit", headerName: "お預かり", minWidth: 200, hide: true },
        { field: "memo", headerName: "メモ", minWidth: 200, hide: true },
        { field: "change", headerName: "お釣り", minWidth: 200, hide: true },
        { field: "seat_number", headerName: "座席番号", minWidth: 200, hide: true },
        {
            field: "payment_methods",
            headerName: "決済方法",
            width: 100,
            hide: true,

            renderCell: (params) => {
                switch (params.row?.payment_methods) {
                    case "0":
                        return "現金";
                    case "1":
                        return "カード";
                    case "2":
                        return "QR決済";
                    default:
                        return "不明";
                }
            },
        },

        {
            field: "amount",
            headerName: "料金",
            width: 100,
            type: "number",
            hide: false,
            valueGetter: (params) => params.row?.amount,
            // valueGetter: (params) => Number(params.row?.amount).toLocaleString(),
        },
        {
            field: "削除",
            headerName: "",
            sortable: false,
            width: 100,
            align: "right",
            // flex: 1,
            renderCell: (params) => (
                <Button variant="contained" color="primary" onClick={() => OpenAccountingDetailPopup(params.row)}>
                    伝票
                </Button>
            ),
        },
        {
            field: "詳細",
            headerName: "",
            sortable: false,
            width: 100,
            align: "right",
            // flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        dispatch(CheckHistory_set(["check_data", params.row]));
                        dispatch(CheckHistory_set(["OpenCheckPopup", true]));
                    }}
                >
                    修正
                </Button>

                // <Button variant="contained" color="error" onClick={() => OnDeleteVisit(params.row)}>
                //     削除
                // </Button>
            ),
        },
    ];

    return (
        <>
            <div style={{ height: 800, width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row.visit_id}
                    rows={CheckHistoryState.visit_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    disableSelectionOnClick
                    pagination
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>

            <Button onClick={HandleDownload}>ダウンロード</Button>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計金額 {Number(total_amount).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計税金額 {Number(total_tax).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計手数料金額 {Number(total_commission).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計 繰り上げ・繰り下げ金額 {Number(total_round_amount).toLocaleString()}円
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                合計 利益額 {Number(total_amount - total_tax - total_commission).toLocaleString()}円
            </Grid>
        </>
    );
};
export default Supplier;
