import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    image_list: [],
    image_type_list: [],
};
export const GetImageList = (arg) => {
    return AsyncFunction({
        type_name: "Image/画像一覧取得",
        method: "get",
        url: "/api/GetImageList",
        params: arg,
    });
};

export const PostImage = (arg) => {
    return AsyncFunction({
        type_name: "Image/画像追加",
        method: "post",
        url: "/api/PostImage",
        params: arg,
    });
};

export const DeleteImage = (arg) => {
    return AsyncFunction({
        type_name: "Image/画像削除",
        method: "delete",
        url: "/api/DeleteImage",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Image",
    initialState,
    reducers: {
        image_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("Image") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;
                if (payload?.image_list) {
                    state.image_list = payload?.image_list;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { image_set } = slice.actions;
