import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    user_info: {
        user_id: "",
        email: "",
    },
    user_list: [],
};

export const GetUserList = (arg) => {
    return AsyncFunction({
        type_name: "User/ユーザーリストの取得",
        method: "get",
        url: "/api/GetUserList",
        params: arg,
    });
};
export const GetUserInfo = (arg) => {
    return AsyncFunction({
        type_name: "User/ユーザー情報の取得",
        method: "get",
        url: "/api/GetUserInfo",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "User",
    initialState,
    reducers: {
        User_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("User/") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload.result != "OK") return;

                if (payload?.user_info) {
                    state.user_info = payload?.user_info;
                }
                if (payload?.user_list) {
                    state.user_list = payload?.user_list;
                }

                // if (payload?.user_token) {
                //     state.user_token = payload?.user_token;
                //     state.login_status = "user";
                //     localStorage.setItem("user_token", payload?.user_token);
                // }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { User_set } = slice.actions;
