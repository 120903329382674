import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import InputField from "~/components/inputs/InputField";

import { PutEmail, PostUserOneTime } from "~slices/Information";
import { Link } from "react-router-dom";
import { stubTrue } from "lodash";
import { useHistory } from "react-router";

const ChangeEmail = () => {
    const dispatch = useDispatch();
    const informationState = useSelector((state) => state.Information);
    const [sended, setSended] = useState(false);
    const history = useHistory();

    const HandleGetOnetime = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        const email = elem?.email?.value;

        const params = {
            email: email,
        };
        dispatch(PostUserOneTime(params)).then((data) => {
            if (data.payload.result == "OK") {
                setSended(email);
            }
        });
    };

    const HandleSubmit = (e) => {
        e.preventDefault();
        const elem = e.target.elements;

        const onetime_password = elem?.onetime_password?.value;

        const params = {
            onetime_password: onetime_password,
        };

        dispatch(PutEmail(params)).then((data) => {
            if (data.payload.result == "OK") {
                history.push("/UserInfo");
            }
        });
    };
    const HandleBack = () => {
        setSended(false);
    };

    return (
        <React.Fragment>
            <Container>
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        メールアドレスの変更
                    </Typography>
                    <Grid sx={{ border: "solid 1px", borderRadius: 2, p: 2 }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="div" gutterBottom>
                                現在のメールアドレス
                            </Typography>
                            <Typography variant="div" gutterBottom>
                                {informationState?.user_info?.email}
                            </Typography>
                        </Grid>
                        {sended ? (
                            // 送信後
                            <form onSubmit={HandleSubmit} autoComplete="off" name="onetime_form" key="1">
                                <Grid container direction="column" justifyContent="center" alignItems="center">
                                    <InputField
                                        type="text"
                                        name="onetime_password"
                                        label="ワンタイムパスワード"
                                        required
                                    />
                                    <Button variant="contained" type="submit">
                                        変更
                                    </Button>
                                </Grid>
                            </form>
                        ) : (
                            // 送信前
                            <form onSubmit={HandleGetOnetime} name="email_change_form" key="2">
                                <Grid container direction="column" justifyContent="center" alignItems="center">
                                    <InputField type="email" name="email" label="新しいメールアドレス" required />
                                    新しいメールアドレスにワンタイムパスワードを送信します。
                                    <Button variant="contained" type="submit">
                                        送信
                                    </Button>
                                </Grid>
                            </form>
                        )}
                    </Grid>
                </Grid>
                <Grid container>{sended ? <Button onClick={HandleBack}>戻る</Button> : ""}</Grid>
            </Container>
        </React.Fragment>
    );
};

export default ChangeEmail;
