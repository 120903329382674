import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    menu_list: [],
    menu_type_list: [],
};
export const GetMenuList = (arg) => {
    return AsyncFunction({
        type_name: "Menu/メニュー一覧取得",
        method: "get",
        url: "/api/GetMenuList",
        params: arg,
    });
};

export const PostMenu = (arg) => {
    return AsyncFunction({
        type_name: "Menu/メニュー追加",
        method: "post",
        url: "/api/PostMenu",
        params: arg,
    });
};

export const DeleteMenu = (arg) => {
    return AsyncFunction({
        type_name: "Menu/メニュー削除",
        method: "delete",
        url: "/api/DeleteMenu",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "Menu",
    initialState,
    reducers: {
        Menu_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.match("Menu") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.result != "OK") return;
                    if (payload?.menu_list) {
                        state.menu_list = payload?.menu_list;
                    }
                    if (payload?.menu_type_list) {
                        state.menu_type_list = payload?.menu_type_list;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.match("Accounting") && action.type.endsWith("/fulfilled"),
                (state, action) => {
                    const payload = action.payload;
                    if (payload?.menu_type_list) {
                        state.menu_type_list = payload?.menu_type_list;
                    }
                }
            );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { Menu_set } = slice.actions;
