import React, { useEffect, useState, useLayoutEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { UserLogout } from "~slices/Information";

import NaviDrawer from "~/components/block/NaviDrawer";

export default function NaviBar() {
    const informationState = useSelector((state) => state.Information);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    useEffect(() => {
        const path = history.location.pathname;
        // ログインステータスによってリダイレクトするかを判定する。
        switch (informationState.login_status) {
            case "user":
                if (path == "/login" || path == "/signup") {
                    history.push("/");
                }
                break;
            case "failure":
                history.push("/login");
                break;
            case "logout":
                history.push("/login");
                break;
            default:
                break;
        }
    }, [informationState.login_status]);

    const openDrawer = () => {
        setOpen(true);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        handleClose();
        dispatch(UserLogout());
    };

    const onLink = (link) => {
        handleClose();
        history.push(link);
    };

    return (
        <Box sx={{ flexGrow: 1, height: "4rem" }}>
            <NaviDrawer open={open} onClose={() => setOpen(false)} />
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={openDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => onLink("/")}>
                        {informationState.config.system_name}
                    </Typography>
                    {informationState?.login_status == "user" ? (
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                {/* <AccountCircle /> */}

                                <Avatar
                                    alt={informationState?.user_info?.user_name}
                                    src={informationState?.user_info?.image_path}
                                    sx={{ width: 30, height: 30 }}
                                />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => onLink(`/User?user_id=${informationState?.user_info?.user_id}`)}
                                >
                                    ユーザー情報
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
                            </Menu>
                        </div>
                    ) : (
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => onLink("/signup")}>新規登録</MenuItem>
                                <MenuItem onClick={() => onLink("/login")}>ログイン</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
