import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncFunction } from "~/components/functions/AsyncFunction";

// Stateの初期状態
const initialState = {
    timeline_data: [],
};
export const GetTimeLine = (arg) => {
    return AsyncFunction({
        type_name: "TimeLine/タイムライン取得",
        method: "get",
        url: "/api/GetTimeLine",
        params: arg,
    });
};

export const PostTimeLine = (arg) => {
    return AsyncFunction({
        type_name: "TimeLine/タイムライン投稿",
        method: "post",
        url: "/api/PostTimeLine",
        params: arg,
    });
};

export const DeleteTimeLine = (arg) => {
    return AsyncFunction({
        type_name: "TimeLine/タイムライン削除",
        method: "delete",
        url: "/api/DeleteTimeLine",
        params: arg,
    });
};

// Sliceを生成する
const slice = createSlice({
    name: "TimeLine",
    initialState,
    reducers: {
        TimeLine_set: (state, action) => {
            const name = action.payload[0];
            const value = action.payload[1];
            state[name] = value;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action.type.match("TimeLine") && action.type.endsWith("/fulfilled"),
            (state, action) => {
                const payload = action.payload;
                if (payload?.result != "OK") return;

                if (payload?.timeline_data) {
                    state.timeline_data = payload?.timeline_data;
                }
            }
        );
    },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { TimeLine_set } = slice.actions;
