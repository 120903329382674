import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { GetStaffList } from "~slices/Staff";
import { Attendance_set, PostAttendance, GetAttendanceList, PutAttendance } from "~slices/Attendance";
import AttendanceTable from "~/components/block/AttendanceTable";

const AttendanceCreate = () => {
    const dispatch = useDispatch();
    const StaffState = useSelector((state) => state.Staff);
    const AttendanceState = useSelector((state) => state.Attendance);
    const [staff_id, setStaffId] = React.useState(0);

    React.useEffect(() => {
        dispatch(GetStaffList()).then((data) => {});
    }, []);

    const HandleStaffId = (e) => {
        setStaffId(e.target.value);
    };
    const onAttendance = () => {
        const staff_data = StaffState.staff_list.filter((item) => item.staff_id == staff_id)[0];
        let selected_staff_name = staff_data?.staff_name;
        if (!selected_staff_name) {
            selected_staff_name = window.prompt("名前を入力してください");
        }
        if (!selected_staff_name) {
            selected_staff_name = "名無し";
        }

        const params = {
            ...AttendanceState.search_criteria,
            create_data: {
                staff_id: staff_id,
                staff_name: staff_data?.staff_name ? staff_data.staff_name : selected_staff_name,
                start_time: FormatTime(new Date(), "date_time"),
                hourly: staff_data?.hourly ? staff_data?.hourly : 0,
                staff_id: staff_id,
                pay_type: 1,
            },
        };
        // console.log(params);
        dispatch(PostAttendance(params)).then((data) => {
            dispatch(Attendance_set(["AttendanceCreateDialog", false]));
        });
    };

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ width: "100%", p: 2 }}>
            <FormControl>
                <InputLabel id="select-menu-label">従業員名</InputLabel>
                <Select
                    sx={{ m: 1, width: "12rem" }}
                    fullWidth
                    labelId="select-menu-label"
                    label="従業員名"
                    value={staff_id}
                    onChange={HandleStaffId}
                >
                    <MenuItem value={0}>未選択</MenuItem>
                    {StaffState.staff_list.map((item, index) => (
                        <MenuItem key={index} value={item.staff_id}>
                            {item.staff_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button onClick={onAttendance} variant="contained" sx={{ height: "3.5rem", width: "6rem" }}>
                出勤
            </Button>
        </Grid>
    );
};
export default AttendanceCreate;
