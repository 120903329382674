import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";
import { format, parse, differenceInMinutes } from "date-fns";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
    Dialog,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { GetStaffList } from "~slices/Staff";
import { Attendance_set, PostAttendance, GetAttendanceList, PutAttendance, DeleteAttendance } from "~slices/Attendance";

const AttendanceDetailPopup = () => {
    const dispatch = useDispatch();
    const AttendanceState = useSelector((state) => state.Attendance);
    const attendance_data = AttendanceState.attendance_data;
    const theme = useTheme();
    const StaffState = useSelector((state) => state.Staff);
    const informationState = useSelector((state) => state.Information);
    const permission = informationState.user_info.permission;

    const [startTime, setStartTime] = React.useState(attendance_data.start_time);
    const [endTime, setEnd_time] = React.useState(
        attendance_data.end_time ? attendance_data.end_time : FormatTime(new Date(), "date_time")
    );
    const [memo, setMemo] = React.useState(attendance_data.memo);
    const [hourly, setHourly] = React.useState(attendance_data.hourly);
    const [pay_type, setPayType] = React.useState(attendance_data.pay_type);
    const [salary, setSalary] = React.useState(attendance_data.salary);

    const start = ParseTime(startTime);
    const end = ParseTime(endTime);
    const diff = differenceInMinutes(end, start);

    React.useEffect(() => {
        dispatch(GetStaffList()).then((data) => {});
    }, []);

    const HandleStartTime = (e) => {
        setStartTime(e.target.value);
        const params = {
            attendance_id: attendance_data?.attendance_id,
            ...AttendanceState.search_criteria,
            update_data: {
                start_time: e.target.value,
                working_hours: diff ? diff : 0,
            },
        };
        dispatch(PutAttendance(params)).then((data) => {});
    };

    const HandleSetMemo = (e) => {
        const params = {
            attendance_id: attendance_data?.attendance_id,
            ...AttendanceState.search_criteria,
            update_data: {
                memo: e.target.value,
            },
        };
        dispatch(PutAttendance(params)).then((data) => {});
    };
    const HandleSetHourly = (e) => {
        const params = {
            attendance_id: attendance_data?.attendance_id,
            ...AttendanceState.search_criteria,
            update_data: {
                hourly: e.target.value,
            },
        };
        dispatch(PutAttendance(params)).then((data) => {});
    };

    const HandlePayType = (e) => {
        setPayType(e.target.value);
        const params = {
            attendance_id: attendance_data?.attendance_id,
            ...AttendanceState.search_criteria,
            update_data: {
                pay_type: e.target.value,
            },
        };
        dispatch(PutAttendance(params)).then((data) => {});
    };

    const HandleSetSalary = (e) => {
        const params = {
            attendance_id: attendance_data?.attendance_id,
            ...AttendanceState.search_criteria,
            update_data: {
                salary: e.target.value,
            },
        };
        dispatch(PutAttendance(params)).then((data) => {});
    };

    const HandleEndTime = (e) => {
        setEnd_time(e.target.value);
    };

    const HandleDelete = () => {
        const res = confirm(`削除しますか?`);
        if (!res) return;
        const params = {
            attendance_id: attendance_data?.attendance_id,
            ...AttendanceState.search_criteria,
        };
        dispatch(DeleteAttendance(params)).then((data) => {
            dispatch(Attendance_set(["AttendanceDetailDialog", false]));
        });
    };

    const HandleClockingOut = () => {
        const staff_data = StaffState.staff_list.find((v) => v.staff_id == attendance_data.staff_id);
        let salary = 0;

        if (staff_data) {
            salary = Math.round(Number(diff * (staff_data.hourly / 60)));
        }

        // StaffState
        const params = {
            attendance_id: attendance_data?.attendance_id,
            ...AttendanceState.search_criteria,
            update_data: {
                end_time: endTime,
                working_hours: diff,
                salary: salary,
            },
        };
        dispatch(PutAttendance(params)).then((data) => {
            dispatch(Attendance_set(["AttendanceDetailDialog", false]));
        });
    };

    return (
        <>
            <Box sx={{ minWidth: "300px", pb: 3 }}>
                <Box sx={{ mb: 3, background: theme.palette.primary.main, p: 2, fontSize: "1.5rem" }}>
                    {attendance_data.staff_name}
                </Box>

                <Box sx={{ minWidth: "300px", pr: 3, pl: 3 }}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                        <TextField
                            sx={{ m: 1 }}
                            label="出勤時間"
                            type="datetime-local"
                            value={startTime}
                            onChange={HandleStartTime}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={permission == "admin" || permission == "god" ? false : true}
                        >
                            来店時間
                        </TextField>
                        <TextField
                            sx={{ m: 1 }}
                            label="退勤時間"
                            type="datetime-local"
                            value={endTime}
                            onChange={HandleEndTime}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={permission == "admin" || permission == "god" ? false : true}
                        >
                            退勤時間
                        </TextField>
                        {permission == "admin" || permission == "god" ? (
                            <>
                                <FormControl>
                                    <InputLabel id="select-pay_type-label">給与形態</InputLabel>
                                    <Select
                                        sx={{ m: 1, width: "12rem" }}
                                        fullWidth
                                        labelId="select-pay_type-label"
                                        label="給与形態"
                                        value={pay_type}
                                        onChange={HandlePayType}
                                        disabled={permission == "admin" || permission == "god" ? false : true}
                                    >
                                        <MenuItem value={1}>時給</MenuItem>
                                        <MenuItem value={2}>日給</MenuItem>
                                        <MenuItem value={3}>月給</MenuItem>
                                        <MenuItem value={10}>ボーナス</MenuItem>
                                        <MenuItem value={20}>手当</MenuItem>
                                        <MenuItem value={99}>その他</MenuItem>
                                    </Select>
                                </FormControl>

                                <Box sx={{ p: 1 }}>
                                    勤務時間 {Math.floor(diff / 60)}時間{diff % 60}分
                                </Box>
                                <TextField
                                    label="時給"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    value={hourly ? hourly : ""}
                                    onChange={(e) => setHourly(e.target.value)}
                                    onBlur={HandleSetHourly}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={permission == "admin" || permission == "god" ? false : true}
                                />

                                <TextField
                                    label="給与"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    value={salary ? salary : 0}
                                    onChange={(e) => setSalary(e.target.value)}
                                    onBlur={HandleSetSalary}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={permission == "admin" || permission == "god" ? false : true}
                                />
                            </>
                        ) : (
                            ""
                        )}
                        <TextField
                            label="メモ"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            sx={{ mt: 2 }}
                            value={memo ? memo : ""}
                            onChange={(e) => setMemo(e.target.value)}
                            onBlur={HandleSetMemo}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Button
                                variant="contained"
                                color="error"
                                onClick={HandleDelete}
                                sx={{ height: "5rem", width: "10rem", m: 1 }}
                            >
                                削除
                            </Button>
                            <Button
                                variant="contained"
                                onClick={HandleClockingOut}
                                sx={{ height: "5rem", width: "10rem", m: 1 }}
                            >
                                退勤
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default AttendanceDetailPopup;
