import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { GetUserList } from "~slices/User";
import { DataGrid, jaJP } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

import { now } from "lodash";
import { useHistory } from "react-router";

const UserList = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        dispatch(GetUserList());
    }, []);

    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <Typography variant="h3" align="center" sx={{ pb: 2 }}>
                    ユーザー管理
                </Typography>

                <UserTable />
            </Container>
        </React.Fragment>
    );
};

const UserTable = (props) => {
    const UserState = useSelector((state) => state.User);
    const dispatch = useDispatch();
    // const purchase_list = PurchaseState.purchase_list;
    // const DellPurchase = (purchase_data) => {
    //     dispatch(
    //         DeletePurchase({
    //             month: month,
    //             year: year,
    //             purchase_id: purchase_data.purchase_id,
    //         })
    //     );
    // };
    const columns = [
        { field: "user_id", headerName: "ユーザーID", minWidth: 100, hide: true },
        {
            field: "ユーザー名",
            headerName: "ユーザー名",
            minWidth: 60,
            hide: false,
            valueGetter: (params) => params?.row.user_info_data?.user_name,
        },
        { field: "email", headerName: "メールアドレス", minWidth: 110, flex: 1, hide: false },
        { field: "user_type", headerName: "区分", minWidth: 40, hide: false },
        { field: "situation", headerName: "状況", minWidth: 40, hide: false },
        { field: "permission", headerName: "権限", minWidth: 40, hide: false },
    ];
    return (
        <>
            <div style={{ height: 800, width: "100%" }}>
                <DataGrid
                    getRowId={(row) => row.user_id}
                    rows={UserState.user_list}
                    columns={columns}
                    // rowCount={party_list_total}
                    // pageSize={limit}
                    // onPageChange={setPage}
                    // paginationMode="server"
                    // sortingMode="server"
                    // rowsPerPageOptions={[limit]}
                    // disableColumnMenu
                    disableSelectionOnClick
                    pagination
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
        </>
    );
};

export default UserList;
