import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "~/components/inputs/InputField";

import { UserLogin } from "~slices/Information";
import { Link } from "react-router-dom";

import { GetMenuList, Menu_set, PostMenu, DeleteMenu } from "~slices/Menu";

import { styled } from "@mui/material/styles";

import { FormatTime, ParseTime } from "~/components/functions/DateTimeFunctions";

import { now } from "lodash";

const HeadGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // background: "#9bc6fa",
    // fontSize: "0.8rem",
    textAlign: "center",
    borderRadius: 2,
    fontWeight: "bold",
    fontSize: "1rem",
});
const BodyGrid = styled(Grid)({
    padding: 2,
    border: "1px solid grey",
    // fontSize: "0.9rem",
    overflowWrap: "break-word",
    borderRadius: 2,
    fontSize: "1rem",
});

const MenuSetting = () => {
    const dispatch = useDispatch();
    const MenuState = useSelector((state) => state.Menu);
    const menu_type_list = MenuState.menu_type_list;
    const [type, setType] = React.useState(3);
    const [menu_name, setMenuName] = React.useState("");
    const [menu_price, setMenuPrice] = React.useState(1000);
    React.useEffect(() => {
        const params = {
            type: type,
        };
        dispatch(GetMenuList(params)).then((data) => {
            console.log(data);
        });
    }, []);
    const CreateMenu = () => {
        const params = {
            type: type,
            create_data: {
                type: type,
                menu_name: menu_name,
                menu_price: menu_price,
            },
        };
        dispatch(PostMenu(params)).then((data) => {
            console.log("menu");
        });
    };

    const HandleChangeType = (e) => {
        setType(e.target.value);

        const params = {
            type: e.target.value,
        };
        dispatch(GetMenuList(params)).then((data) => {});
    };
    return (
        <React.Fragment>
            <Container sx={{ pt: 2 }}>
                <Typography variant="h3" align="center" sx={{ pb: 2 }}>
                    商品管理
                </Typography>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <FormControl>
                        <InputLabel id="select-type-label">種別</InputLabel>
                        <Select
                            sx={{ mb: 1, width: "12rem" }}
                            fullWidth
                            labelId="select-type-label"
                            label="種別"
                            value={type}
                            onChange={HandleChangeType}
                        >
                            {menu_type_list.map((val, index) => (
                                <MenuItem key={index} value={val.type}>
                                    {val.menu_type_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button variant="contained" component={Link} to="/MenuTypeSetting" sx={{ height: "3rem", mt: 1 }}>
                        商品種別管理
                    </Button>
                </Grid>
                追加
                <Grid sx={{ border: "1px solid grey", p: 2 }} container direction="row" justifyContent="space-around">
                    <TextField
                        sx={{ m: 1, width: "20rem" }}
                        label="名前"
                        value={menu_name}
                        onChange={(e) => setMenuName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        sx={{ m: 1, width: "8rem" }}
                        label="価格"
                        value={menu_price}
                        onChange={(e) => setMenuPrice(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">円</InputAdornment>,
                        }}
                    />
                    <Button variant="contained" sx={{ height: "4rem", width: "6rem" }} onClick={CreateMenu}>
                        登録
                    </Button>
                </Grid>
                <MenuTable type={type} />
            </Container>
        </React.Fragment>
    );
};

const MenuTable = (props) => {
    const MenuState = useSelector((state) => state.Menu);
    const dispatch = useDispatch();
    const menu_list = MenuState.menu_list;
    const DellMenu = (menu_data) => {
        dispatch(
            DeleteMenu({
                type: props.type,
                menu_id: menu_data.menu_id,
            })
        );
    };
    // const menu_list = [{ menu_name: "aaaa", manu_price: 1000 }];

    return (
        <>
            <Grid container sx={{ mt: 1 }}>
                <HeadGrid item xs={8}>
                    品名
                </HeadGrid>
                <HeadGrid item xs={2}>
                    単価
                </HeadGrid>
                <HeadGrid item xs={2}></HeadGrid>

                {menu_list.map((menu, index) => (
                    <React.Fragment key={index}>
                        <BodyGrid item xs={8} sx={{ p: "4px" }}>
                            {menu.menu_name}
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right", pt: 1, pr: 1 }}>
                            {menu.menu_price}円
                        </BodyGrid>
                        <BodyGrid item xs={2} sx={{ textAlign: "right" }}>
                            <Button fullWidth color="error" onClick={() => DellMenu(menu)}>
                                削除
                            </Button>
                        </BodyGrid>
                    </React.Fragment>
                ))}
                {/* 合計{price}円
                <Grid>
                    税込み
                    <div style={{ fontSize: "2rem", display: "inline" }}>{Math.floor(price * 1.1)}</div>円
                </Grid> */}
            </Grid>
        </>
    );
};

export default MenuSetting;
