import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";

export const MessageLeft = (props) => {
    const { data, user_info } = props;
    const pattern = /^(https?|ftp)(:\/\/[\w\/:%#\$&\?\(\)~\.=\+\-]+)/;

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ marginTop: "0.5rem" }}>
            <Grid item sx={{ paddingRight: "0.5rem", marginTop: "0", marginBottom: "auto" }}>
                <Avatar
                // alt={displayName}

                // src={user_info?.image_path}
                // onClick={AvatarClick}
                ></Avatar>
            </Grid>
            <Grid item xs sx={{ background: "#a7f761", padding: "0.5rem", borderRadius: "0.5rem" }}>
                {data.text.split("\n").map((t, i) => (
                    <React.Fragment key={i}>
                        {pattern.test(t) ? (
                            <a href={t} rel="stylesheet">
                                {t}
                            </a>
                        ) : (
                            <p>{t}</p>
                        )}
                    </React.Fragment>
                ))}

                {data.text}
            </Grid>
        </Grid>
    );
};
export const MessageRight = (props) => {
    const { data, user_info } = props;
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ marginTop: "0.5rem" }}>
            <Grid item xs sx={{ background: "#a7f761", padding: "0.5rem", borderRadius: "0.5rem" }}>
                {data.text}
            </Grid>
            <Grid item sx={{ paddingLeft: "0.5rem" }}>
                <Avatar
                    // alt={displayName}

                    src={user_info?.image_path}
                    // onClick={AvatarClick}
                ></Avatar>
            </Grid>
        </Grid>
    );
};

const ChatMessage = (props) => {
    const { data, user_info, ...other } = props;
    return (
        <>
            {data?.user_id == user_info?.user_id ? (
                <MessageRight data={data} user_info={user_info} />
            ) : (
                <MessageLeft data={data} user_info={user_info} />
            )}
        </>
    );
};

export default ChatMessage;
